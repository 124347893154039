import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';

import Logo from '../../../../assets/svgs/logo.svg';
import Bg from '../../../../assets/images/auth-bg.png';
import SignupBanner from '../../../../assets/images/sign-up.png';

import TextInput from '../../../../components/inputs/Text';
import Button from '../../../../components/inputs/Button';
import handleFetch from '../../../../services/api/handleFetch';
import notification from '../../../../utilities/notification';
import Loading from '../../../../components/commons/Loading';
import AuthFooter from '../../../../components/commons/AuthFooter';
import CheckBox from '../../../../components/inputs/CheckBox';
import PhoneNoInput from '../../../../components/inputs/PhoneNo';
import SignupSuccess from './SignupSuccess';

function SignupForm() {
  const params = useParams();
  const [, setCookie] = useCookies();
  const [form, setForm] = useState(null);
  const [checkTerms, setCheckTerms] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'monieworx - Sign up';
  }, []);

  useEffect(() => {
    if (params?.refCode) {
      setForm({ referralCode: params.refCode });
    }
  }, [params]);

  const handleChange = (val, type = 'input', inputName = '') => {
    if (type === 'input') {
      const { value, name } = val.target;
      setForm((state) => ({ ...state, [name]: value }));
    } else {
      setForm((state) => ({ ...state, [inputName]: val }));
    }
  };

  const signupMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      localStorage.setItem('d', JSON.stringify(res?.data));
      const { user, token } = res?.data || {};
      delete user?.investment_goal;
      delete user?.investments;
      const data = { user, token };
      setCookie('data', data, { secure: true, sameSite: true });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateForm = () => {
    if (!form?.firstName) return 'First name is required';
    if (!form?.lastName) return 'Last name is required';
    if (!form?.email) return 'Email address is required';
    if (!/^([a-zA-Z0-9_\-.&]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(form?.email)) {
      return 'Please, enter a valid email';
    }
    if (!form?.phone) return 'Phone number is required';
    if (form?.phone.length < 12) return 'Please, enter a valid phone number';
    if (!form?.password) return 'Password is required';
    if (form.password.length < 8) return 'Password must be 8 or more character long';
    if (form?.password !== form?.confirmPassword) return 'Password mismatch';
    if (!checkTerms) return 'Kindly accept the Terms of service & Privacy policy to proceed';
    return null;
  };

  const handleSignup = (e) => {
    e?.preventDefault();

    const error = validateForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    const body = {
      f_name: form?.firstName,
      l_name: form?.lastName,
      email: form?.email,
      phone: form?.phone,
      password: form?.password,
      password_confirmation: form?.confirmPassword,
      referred_by: form?.referralCode
    };

    signupMutation.mutate({
      endpoint: 'register', method: 'POST', body, auth: false
    });
  };

  const { isLoading, isSuccess } = signupMutation;

  return (
    <>
      {isLoading && <Loading />}

      <div style={{ background: `no-repeat url(${Bg})` }} className="relative w-full h-full min-h-screen !bg-cover px-10 pt-24 pb-5">
        <div className="w-full max-w-6xl m-auto overflow-hidden mb-20 bg-white rounded-b-3xl border border-primary">
          <div className="w-full flex">
            <div className="w-full mdx2:w-2/3 px-8 py-10">
              <img
                alt="Logo"
                src={Logo}
                className="w-64 h-auto mb-5"
              />

              <h3 className="font-semibold text-xl">Start making monie moves</h3>

              <div className="flex flex-wrap justify-between mt-5 mb-5">
                <p className="text-error">Get your monie working in 90 seconds.</p>
                <p className="">
                  <span className="text-primary">Have an account?&nbsp;</span>
                  <Link className="text-error font-bold hover:underline" to="/">Login</Link>
                </p>
              </div>

              <div className="w-full">
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full sm:w-1/2 p-3">
                    <TextInput
                      label="First Name"
                      value={form?.firstName || ''}
                      name="firstName"
                      onChange={handleChange}
                      placeholder="Enter First name as shown on government ID"
                    />
                  </div>
                  <div className="w-full sm:w-1/2 p-3">
                    <TextInput
                      label="Last Name"
                      value={form?.lastName || ''}
                      name="lastName"
                      onChange={handleChange}
                      placeholder="Enter Last name as shown on government ID"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full sm:w-1/2 p-3">
                    <TextInput
                      label="Email Address"
                      value={form?.email || ''}
                      name="email"
                      type="email"
                      onChange={handleChange}
                      placeholder="example@mail.com"
                    />
                  </div>
                  <div className="w-full sm:w-1/2 p-3">
                    <PhoneNoInput
                      label="Phone Number"
                      value={form?.phone || ''}
                      name="phone"
                      onChange={(val) => handleChange(val, 'phone', 'phone')}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full sm:w-1/2 p-3">
                    <TextInput
                      label="Password"
                      value={form?.password || ''}
                      name="password"
                      type="password"
                      onChange={handleChange}
                      placeholder="Minimum 8 characters"
                    />
                  </div>
                  <div className="w-full sm:w-1/2 p-3">
                    <TextInput
                      label="Confirm Password"
                      value={form?.confirmPassword || ''}
                      name="confirmPassword"
                      type="password"
                      onChange={handleChange}
                      placeholder="Minimum 8 characters"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full sm:w-1/2 p-3">
                    <TextInput
                      label="Referral Code (Optional)"
                      value={form?.referralCode || ''}
                      name="referralCode"
                      onChange={handleChange}
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="w-full mt-5">
                  <CheckBox
                    name="checkTerms"
                    checked={checkTerms}
                    onChange={() => setCheckTerms((state) => !state)}
                    label={(
                      <p className="text-xs max-w-xs">
                        Creating an account means you are okay with our&nbsp;
                        <a
                          className="text-error font-bold hover:underline"
                          href="https://monieworx.com/terms-of-service"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of service
                        </a>
                        &nbsp;&&nbsp;
                        <a
                          className="text-error font-bold hover:underline"
                          href="https://monieworx.com/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy policy
                        </a>
                      </p>
                    )}
                  />
                </div>

                <Button className="rounded-full mt-10" paddingY="py-4" paddingX="px-20" onClick={handleSignup}>Sign Up</Button>
              </div>
            </div>

            <div style={{ background: `no-repeat url(${SignupBanner}) center` }} className="!bg-cover hidden mdx2:block w-1/3" />
          </div>
        </div>

        <AuthFooter />
      </div>

      {isSuccess && <SignupSuccess />}
    </>
  );
}

export default SignupForm;
