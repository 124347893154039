import React from 'react';
import SignupForm from './components/SignupForm';

function Signup() {
  return (
    <SignupForm />
  );
}

export default Signup;
