import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';
import AuthCode from 'react-auth-code-input';

import Button from '../../../../components/inputs/Button';
import handleFetch from '../../../../services/api/handleFetch';
import notification from '../../../../utilities/notification';
import Loading from '../../../../components/commons/Loading';

function ValidateOtp({ onNext = () => {} }) {
  const [cookie] = useCookies(['data']);
  const [otp, setOtp] = useState(null);

  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      onNext();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const resendOtpMutation = useMutation(handleFetch, {
    onSuccess: () => {
      notification({
        title: 'Successful',
        message: 'OTP successfully resent.',
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleResend = () => {
    resendOtpMutation.mutate({
      endpoint: 'forgotPassword', extra: '/send-otp', method: 'POST', body: { email: cookie?.data?.email }
    });
  };

  const validateForm = () => {
    if (otp?.length < 4) return 'OTP is required';
    return null;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const error = validateForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    submitMutation.mutate({
      endpoint: 'forgotPassword', extra: '/verify-otp', method: 'POST', body: { otp, email: cookie?.data?.email }
    });
  };

  const { isLoading: isResending } = resendOtpMutation;
  const { isLoading } = submitMutation;

  return (
    <>
      {(isLoading || isResending) && <Loading />}

      <h3 className="font-semibold text-xl">Authorise password change</h3>

      <div className="flex flex-wrap justify-between mt-5 mb-5">
        <p className="text-error">{`Enter 4-digit Code code we have sent to ${cookie?.data?.email || ''}`}</p>
      </div>

      <div className="w-full">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full sm:w-1/2 p-3">
            <AuthCode
              value={otp || ''}
              length={4}
              allowedCharacters="numeric"
              containerClassName="w-full flex space-x-3"
              inputClassName="w-1/4 border-b-2 border-blue focus:border-primary outline-none text-center text-3xl font-bold text-blue focus:text-primary py-2"
              onChange={(val) => setOtp(val)}
            />
          </div>
        </div>

        <Button className="rounded-full mt-10" paddingY="py-4" paddingX="px-20" bgColor="bg-error" onClick={handleSubmit}>Submit</Button>

        <p className="mt-5 text-primary">
          <span className="">Didn’t receive the code?&nbsp;</span>
          <button
            type="button"
            onClick={handleResend}
            className="font-bold text-base hover:underline"
          >
            Resend Code
          </button>
        </p>
      </div>
    </>
  );
}

export default ValidateOtp;
