/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-responsive-modal';
import { useMutation } from 'react-query';
import { BsCreditCard2BackFill, BsBank } from 'react-icons/bs';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { TiWarningOutline } from 'react-icons/ti';
import { useCookies } from 'react-cookie';

import BalanceIcon from '../../../assets/svgs/balance-icon.svg';
import TokenIcon from '../../../assets/jsx-svgs/TokenIcon';

import Loading from '../../../components/commons/Loading';
import notification from '../../../utilities/notification';
import handleFetch from '../../../services/api/handleFetch';
import useGetQuery from '../../../hooks/useGetQuery';
import BankTransferInvestmentNotification from './BankTransferInvestmentNotification';
import Button from '../../../components/inputs/Button';

function MakePayment({
  amount, offerId, onClose = () => {}, onShowSubscription
}) {
  const [formIndex, setFormIndex] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showBankAccount, setShowBankAccount] = useState(null);
  const paymentRef = useRef('');
  const navigate = useNavigate();
  const [, setCookie] = useCookies(['ac']);

  const { data, status, error } = useGetQuery({ endpoint: 'wallet', queryKey: ['wallet'] });

  const accountBal = Number(data?.data?.wallet?.account_balance);

  useEffect(() => {
    if (status === 'success') {
      data?.data?.bank_cards?.forEach((item) => {
        if (item?.primary) setSelectedCard(item?.id);
      });
    }
  }, [status]);

  const submitMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (paymentRef.current === 'bank-transfer') {
        setShowBankAccount({ status: true, response: res?.data });
      } else {
        notification({
          title: 'Success',
          message: res?.msg || 'Your investment is successful.',
          type: 'success'
        });
        onShowSubscription(res?.data);
        onClose();
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleSubmit = (paymentType = 'wallet') => {
    if (paymentType === 'card' && !selectedCard) {
      notification({
        title: '',
        message: 'Please select a card',
        type: 'danger'
      });
      return;
    }
    paymentRef.current = paymentType;
    const body = {
      amount,
      payment_type: paymentType,
      offer_id: offerId,
      card_id: formIndex === 1 ? selectedCard : null
    };

    submitMutation.mutate({
      endpoint: 'portfolio', extra: '/checkout', method: 'POST', body
    });
  };

  const closeBankAccountModal = () => {
    const res = showBankAccount?.response;
    onShowSubscription(res);
    setShowBankAccount(null);
    onClose();
  };

  const handleAddCard = () => {
    setCookie('ac', { status: 'requesting', amount, oid: offerId });
    navigate('/wallet/cards');
  };

  if (showBankAccount?.status) {
    return (
      <BankTransferInvestmentNotification
        accountNo={data?.data?.wallet?.account_number}
        onClose={closeBankAccountModal}
      />
    );
  }

  const { isLoading } = submitMutation;

  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-lg' }}>
      {(status === 'loading' || isLoading) && <Loading />}

      <div className="w-full p-5">
        <h2 className="font-bold text-2xl">How would you like to pay?</h2>

        {status === 'loading' && (
          <div className="w-full py-10">
            Loading account details...
          </div>
        )}

        {status === 'success' && (
          <>
            {formIndex === 0 && (
              <div className="w-full mt-12">
                {amount <= accountBal && (
                  <button
                    type="button"
                    onClick={() => handleSubmit()}
                    className="w-full px-5 py-3 text-left mb-5 sm:mb-10 bg-success/40 border-2 border-black rounded-xl hover:bg-success/20"
                  >
                    <div className="flex items-center space-x-3">
                      <img src={BalanceIcon} className="w-10 h-10" alt="" />
                      <h4 className="text-base sm:text-lg font-bold">Pay from monieworx account</h4>
                    </div>
                  </button>
                )}

                <button
                  type="button"
                  onClick={() => handleSubmit('bank-transfer')}
                  className="w-full px-5 py-3 text-left mb-5 sm:mb-10 bg-success/40 border-2 border-black rounded-xl hover:bg-success/20"
                >
                  <div className="flex items-center space-x-3">
                    <span className="w-10 h-10 rounded-[18px] bg-[#232323] p-2">
                      <BsBank className="text-white w-6 h-auto" />
                    </span>
                    <h4 className="text-base sm:text-lg font-bold">Pay with bank transfer</h4>
                  </div>
                </button>

                <button
                  type="button"
                  onClick={() => setFormIndex(1)}
                  className="w-full px-5 py-3 text-left mb-5 sm:mb-10 bg-success/40 border-2 border-black rounded-xl hover:bg-success/20"
                >
                  <div className="flex items-center space-x-3">
                    <span className="w-10 h-10 rounded-[18px] bg-[#232323] p-2">
                      <BsCreditCard2BackFill className="text-white w-6 h-auto" />
                    </span>
                    <h4 className="text-base sm:text-lg font-bold">Pay with card</h4>
                  </div>
                </button>

                <button
                  type="button"
                  onClick={() => handleSubmit('token')}
                  className="w-full px-5 py-3 text-left bg-success/40 border-2 border-black rounded-xl hover:bg-success/20"
                >
                  <div className="flex items-center space-x-3">
                    <span className="w-10 h-10 rounded-[18px] bg-[#232323] p-2">
                      <TokenIcon className="w-6 h-6" color="white" />
                    </span>
                    <h4 className="text-base sm:text-lg font-bold">Pay with aza tokens</h4>
                  </div>
                </button>
              </div>
            )}

            {formIndex === 1 && (
              <div className="w-full mt-5">
                <button type="button" className="inline-flex items-center hover:underline" onClick={() => setFormIndex(0)}>
                  <MdOutlineArrowBack className="mr-2" />
                  Back
                </button>
                {data?.data?.bank_cards?.length > 0 && (
                  <h2 className="font-bold text-lg mb-5 sm:mb-10">Please select card</h2>
                )}

                <div className="w-full mb-5 sm:mb-10">
                  {data?.data?.bank_cards?.map((item) => (
                    <div className="w-full flex justify-between items-center mb-5" key={item?.id}>
                      <div className="flex items-center space-x-3">
                        <span className="w-10 h-10 rounded-[18px] bg-[#232323] p-2">
                          <BsCreditCard2BackFill className="text-white w-6 h-auto" />
                        </span>
                        <div>
                          <h2 className="font-bold text-base">{item?.bank}</h2>
                          <p className="">{`**** **** **** ${item?.last_four_digits}`}</p>
                        </div>
                      </div>

                      <input
                        type="radio"
                        className="w-6 h-6"
                        name="selected"
                        value={item?.id}
                        onChange={(e) => setSelectedCard(e?.target?.value)}
                        checked={Number(selectedCard) === item?.id}
                      />
                    </div>
                  ))}

                  {data?.data?.bank_cards?.length < 1 && (
                    <div className="pt-5 text-center">
                      <TiWarningOutline className="w-16 h-auto mx-auto mb-2 text-error" />
                      <h3 className="font-bold text-lg mb-3">No Card Available</h3>
                      <p>You currently have not added any card yet.</p>

                      <div className="flex justify-center mt-6">
                        <Button
                          className="rounded-full"
                          onClick={handleAddCard}
                        >
                          Add Card
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <button
                  type="button"
                  onClick={() => handleSubmit('card')}
                  disabled={data?.data?.bank_cards?.length < 1}
                  className={`w-full px-5 py-3 text-left ${data?.data?.bank_cards?.length < 1
                    ? 'bg-gray-300 border-gray-400' : 'bg-success/40 hover:bg-success/20 border-black'} border-2 rounded-xl`}
                >
                  <div className="flex items-center space-x-3">
                    <span className="w-10 h-10 rounded-[18px] bg-[#232323] p-2">
                      <BsCreditCard2BackFill className="text-white w-6 h-auto" />
                    </span>
                    <h4 className="text-base sm:text-lg font-bold">Pay with selected card</h4>
                  </div>
                </button>
              </div>
            )}
          </>
        )}

        {status === 'error' && (
          <div className="w-full py-10">
            {String(error)}
          </div>
        )}

      </div>
    </Modal>
  );
}

export default MakePayment;
