import React from 'react';
import { Interweave } from 'interweave';

function DealCard({ data }) {
  return (
    <div className="w-full border border-[#ED7D3A4A] rounded-3xl overflow-hidden relative">
      <div
        style={{ backgroundImage: `url(${data?.cover_photo})` }}
        className="w-full h-48 bg-cover bg-no-repeat bg-center bg-gray-200"
      />

      <div className="w-full relative px-5 -top-10">
        <div className="w-full flex justify-center mb-3">
          <img src={data?.issuer_logo} alt="Company logo" className="w-24 h-24 rounded-full bg-gray-400 text-center" />
        </div>
        <h2 className="text-center mb-5 font-bold text-2xl">{data?.issuer}</h2>
        <h2 className="text-center mb-2 font-bold text-lg">Key Highlights about this Fundraiser</h2>

        <p>
          <Interweave className="leading-loose" content={data?.company_highlights || ''} />
        </p>
      </div>
    </div>
  );
}

export default DealCard;
