import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import CopyToClipboard from 'react-copy-to-clipboard';
import Skeleton from 'react-loading-skeleton';

import useGetQuery from '../../hooks/useGetQuery';
import notification from '../../utilities/notification';

import Loading from '../../components/commons/Loading';
import Button from '../../components/inputs/Button';
import ViewWrapper from '../../wrappers/ViewWrapper';

function AddMonie() {
  const { data, status, error } = useGetQuery({ endpoint: 'wallet', queryKey: ['wallet'] });

  useEffect(() => {
    if (status === 'error') {
      notification({
        title: 'Error',
        message: error?.toString() || 'Something went wrong while fetching data',
        type: 'danger'
      });
    }
  }, [status]);

  return (
    <ViewWrapper header="Add monie">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <Link to="/wallet" className="inline-flex items-center hover:underline">
          <MdOutlineArrowBack className="mr-2" />
          Back
        </Link>
        <h2 className="font-bold  text-2xl sm:text-3xl">Add monie</h2>
      </div>

      <div className="w-full mb-20">
        <h4 className="text-base font-semibold mb-5">Fund your monieworx account through bank transfer</h4>
        <h4 className="text-base font-semibold mb-5 sm:mb-10">Your monieworx account details:</h4>

        <div className="w-full max-w-md">
          <div className="w-full border border-[#FFE7CC] rounded-2xl p-7 mb-7">
            <div className="mb-8">
              <p className="font-semibold">Bank Name:</p>
              <h3 className="text-xl font-bold">KUDA Microfinance Bank</h3>
            </div>

            <div className="">
              <p className="font-semibold">Bank Account Number:</p>
              <h3 className="text-xl font-bold">{data?.data?.wallet?.account_number ?? <Skeleton />}</h3>
            </div>
          </div>

          <p className="mb-16">Copy account details and transfer the amount you want to fund, using your bank Mobile Banking.</p>

          <div className="mb-3">
            <CopyToClipboard
              text={data?.data?.wallet?.account_number}
              onCopy={() => notification({
                message: 'Copied account number to clipboard',
                type: 'success'
              })}
            >
              <Button bgColor="bg-error" className="min-w-max">
                Copy Account Details
              </Button>
            </CopyToClipboard>
          </div>

          <p className="">Powered by KUDA MFB</p>
        </div>
      </div>
    </ViewWrapper>
  );
}

export default AddMonie;
