import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';

import notification from '../../../utilities/notification';
import Loading from '../../../components/commons/Loading';
import handleFetch from '../../../services/api/handleFetch';
import Button from '../../../components/inputs/Button';

function AddCardButton({ initializePayment, onSuccess, btnRef }) {
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(['ac']);

  useEffect(() => {
    if (cookie?.ac?.status === 'requesting') {
      setCookie('ac', { ...cookie?.ac, status: 'adding-card' });
    }
  }, [cookie?.ac]);

  const queryClient = useQueryClient();
  const addCardMutation = useMutation(handleFetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-cards']);
      queryClient.invalidateQueries(['card-provider-info']);
      notification({
        title: 'Success',
        message: 'You have successfully added new card',
        type: 'success'
      });
      if (cookie?.ac?.status === 'adding-card') {
        setCookie('ac', { ...cookie?.ac, status: 'card-added' });
        navigate(`/home/deal-details/${cookie?.ac?.oid}`);
      }
      onSuccess();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleSuccess = (reference) => {
    const body = { reference: reference?.reference };
    addCardMutation.mutate({
      endpoint: 'cards', method: 'POST', body, auth: true
    });
  };

  const { isLoading } = addCardMutation;

  return (
    <>
      {isLoading && <Loading />}
      <Button
        btnRef={btnRef}
        onClick={() => initializePayment(handleSuccess)}
        fontSize="text-base"
        className="w-full min-w-max mb-5"
      >
        <BsPlusLg className="mr-3 w-6 h-auto" />
        Add Card
      </Button>
    </>
  );
}

export default AddCardButton;
