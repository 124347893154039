const prefix = 'api/v1';

const endpoints = {
  register: `${prefix}/register`,
  login: `${prefix}/login`,
  'login-link': `${prefix}/login-link`,
  forgotPassword: `${prefix}/forgot-password`,
  offers: `${prefix}/offers`,
  user: `${prefix}/user`,
  portfolio: `${prefix}/user/investments`,
  wallet: `${prefix}/user/wallet`,
  banks: `${prefix}/wallet/banks`,
  cards: `${prefix}/user/wallet/cards`,
  notifications: `${prefix}/user/notifications`
};

export default endpoints;
