import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { ImArrowDownLeft2, ImArrowUpRight2 } from 'react-icons/im';
import Skeleton from 'react-loading-skeleton';

import TokenIcon from '../../../assets/jsx-svgs/TokenIcon';

import BalanceImg from '../../../assets/svgs/balance-icon.svg';
import TokenSymbol from '../../../assets/svgs/token-symbol.svg';

import Button from '../../../components/inputs/Button';
// import PeriodSelect from '../../../components/inputs/PeriodSelect';
import TokenWithdrawal from './TokenWithdrawal';

function TokenBalance({ data = {} }) {
  const [cookie] = useCookies(['data']);
  const [showWithdrawal, setShowWithdrawal] = useState(false);

  return (
    <>
      <div className="w-full">
        {/* <div className="flex items-center mb-5 sm:mb-10">
          <h4 className="text-base font-semibold mr-4">Your aza tokens balance:</h4>
          <PeriodSelect />
        </div> */}

        <div className="w-full flex flex-wrap items-center">
          <div className="bg-[#7C7C7C26] px-7 py-10 rounded-3xl w-full md:w-7/12">
            <div className="w-full">
              <div className="flex items-center space-x-3 mb-5 sm:mb-10">
                <img src={BalanceImg} className="w-10 h-auto" alt="account" />
                <div className="text-error">
                  <h4 className="text-base font-bold">{(`${cookie?.data?.user?.f_name} ${cookie?.data?.user?.l_name}`).toUpperCase()}</h4>
                  <p className="text-sm">monieworx - AZA TOKENS</p>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div>
                  {(data?.token_balance || data?.token_balance === 0) ? (
                    <div className="flex space-x-2 items-center mb-2 sm:mb-4">
                      <img src={TokenSymbol} className="w-4 h-auto" alt="token" />
                      <h4 className="text-3xl sm:text-4xl font-bold">{data?.token_balance_formatted}</h4>
                    </div>
                  ) : (
                    <h4 className="text-3xl sm:text-4xl font-bold mb-2 sm:mb-4"><Skeleton baseColor="#7C7C7C26" /></h4>
                  )}
                  <p className="text-sm font-bold">Overall Balance</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="w-full md:w-5/12 md:pl-10 flex flex-wrap md:block"> */}
          <div className="w-full md:w-5/12 md:pl-10 hidden">
            <Button
              border
              paddingY="p-0"
              onClick={() => data?.available_tokens && setShowWithdrawal(true)}
              borderColor="border-primary"
              fontSize="text-base sm:text-lg"
              className="overflow-hidden mt-4 sm:mt-7 mr-5 min-w-max !pl-0"
            >
              <span className="flex bg-white text-primary py-2 px-2">
                <TokenIcon className="w-3 h-auto mr-1" color="#5E9942" />
                {data?.available_tokens_formatted ?? <Skeleton width={50} />}
              </span>
              <ImArrowDownLeft2 className="mx-2 w-6 h-auto" />
              Withdraw
            </Button>
            <Button
              fontSize="text-base sm:text-lg"
              border
              borderColor="border-error"
              bgColor="bg-error"
              paddingY="p-0"
              className="overflow-hidden mt-4 sm:mt-7 min-w-max !pl-0"
            >
              <span className="flex bg-white text-error py-2 px-2">
                <TokenIcon className="w-3 h-auto mr-1" color="#EF2D56" />
                {data?.unavailable_tokens_formatted ?? <Skeleton width={50} />}
              </span>
              <ImArrowUpRight2 className="mx-4 w-6 h-auto" />
            </Button>
          </div>
        </div>
      </div>

      {showWithdrawal && <TokenWithdrawal tokenBal={data?.available_tokens} onClose={() => setShowWithdrawal(false)} />}
    </>
  );
}

export default TokenBalance;
