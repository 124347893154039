import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { useMutation, useQueryClient } from 'react-query';
import { BsBank } from 'react-icons/bs';

import Button from '../../../components/inputs/Button';
import Loading from '../../../components/commons/Loading';
import notification from '../../../utilities/notification';
import handleFetch from '../../../services/api/handleFetch';
import useGetQuery from '../../../hooks/useGetQuery';
import { formatCurrency } from '../../../utilities/general';
import { WITHDRAWAL_CHARGE_KUDA, WITHDRAWAL_CHARGE_OTHERS } from '../../../data/constants';

function WalletWithdrawal({ accountBal = 0, onClose = () => {} }) {
  const [amount, setAmount] = useState('');
  const [selectedBank, setSelectedBank] = useState(null);

  const { data, status } = useGetQuery({ endpoint: 'wallet', queryKey: ['wallet'] });
  const selectedAcctId = selectedBank?.split(',')?.[0];
  const selectedBankId = selectedBank?.split(',')?.[1];
  const maxAmount = accountBal - ((selectedBankId === '489') ? WITHDRAWAL_CHARGE_KUDA : WITHDRAWAL_CHARGE_OTHERS);

  useEffect(() => {
    if (status === 'success') {
      data?.data?.bank_accounts?.forEach((item) => {
        if (item?.type !== 'monieworx' && item?.primary) {
          setSelectedBank(`${item?.id},${item?.bank_id}`);
        }
      });
    }
  }, [data, status]);

  const queryClient = useQueryClient();
  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(['wallet']);
      notification({
        title: 'Success',
        message: 'Successfully queued for processing',
        type: 'success'
      });
      onClose();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateForm = () => {
    if (!amount) return 'Enter the amount to withdraw.';
    if (amount > maxAmount) return 'Entered amount exceeds maximum amount';
    if (!selectedBank) return 'Select a bank account to withdraw into.';
    return null;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const err = validateForm();

    if (err) {
      notification({
        title: 'Form Error',
        message: err,
        type: 'danger'
      });
      return;
    }

    const body = {
      amount: Number(amount),
      bank_account_id: Number(selectedAcctId)
    };

    submitMutation.mutate({
      endpoint: 'wallet', extra: '/withdraw', method: 'POST', body
    });
  };

  const { isLoading } = submitMutation;

  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-lg' }}>
      {isLoading && <Loading />}

      <div className="w-full p-5">
        <h2 className="font-bold text-2xl mb-5">Wallet Withdrawal</h2>
        <div className="mb-5">
          <p className="text-primary text-xl font-semibold mb-1">Looks, like you need some cash</p>
          <p className="text-error">Enter the amount you need and select you bank account</p>
        </div>

        <div className="w-full mb-5">
          <p className="font-semibold mb-2">{`Amount: max (${formatCurrency(maxAmount)})`}</p>
          <div className="w-full relative">
            <span className="absolute text-xl font-bold py-3.5 px-2">₦</span>
            <input
              type="number"
              value={amount}
              min={0}
              onChange={(e) => e.target.value <= maxAmount && setAmount(e.target.value)}
              className="w-full text-xl border border-primary outline-none rounded pl-7 pr-3 py-3.5"
            />
          </div>
        </div>

        <div className="w-full mb-5 sm:mb-10">
          <p className="font-semibold mb-2">Select Bank account:</p>
          {data?.data?.bank_accounts?.map((item) => item?.type !== 'monieworx' && (
            <div className="w-full flex justify-between items-center mb-4" key={item?.id}>
              <div className="flex items-center space-x-3">
                <span className="w-10 h-10 rounded-[18px] bg-[#232323] p-2">
                  <BsBank className="text-white w-6 h-auto" />
                </span>
                <div>
                  <h2 className="font-bold text-sm">{item?.account_name}</h2>
                  <p className="">{`${item?.bank?.name} - ${item?.account_number}`}</p>
                  {Number(selectedAcctId) === item?.id && (
                    <p className="text-xs text-error">
                      {`Bank charge: ₦${selectedBankId === '489' ? WITHDRAWAL_CHARGE_KUDA : WITHDRAWAL_CHARGE_OTHERS}/Transfer`}
                    </p>
                  )}
                </div>
              </div>

              <input
                type="radio"
                className="w-6 h-6"
                name="selected"
                value={`${item?.id},${item?.bank_id}`}
                onChange={(e) => setSelectedBank(e?.target?.value)}
                checked={Number(selectedAcctId) === item?.id}
              />
            </div>
          ))}
        </div>

        <Button
          className="rounded-full mt-10"
          paddingY="py-4"
          paddingX="px-20"
          bgColor="bg-error"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default WalletWithdrawal;
