import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';

import Button from '../../components/inputs/Button';
import ViewWrapper from '../../wrappers/ViewWrapper';
import TextInput from '../../components/inputs/Text';
import useGetQuery from '../../hooks/useGetQuery';
import Loading from '../../components/commons/Loading';
import notification from '../../utilities/notification';
import handleFetch from '../../services/api/handleFetch';
import { maskValue } from '../../utilities/general';

function VerifyIdentity() {
  const [ids, setIds] = useState({});
  const apiCount = useRef(0);
  const willReload = useRef(false);

  const {
    data, status, error, isFetched
  } = useGetQuery({ endpoint: 'user', queryKey: ['user'] });

  useEffect(() => {
    if (status === 'success' || isFetched) {
      const { identities } = data?.data?.user || {};
      const res = {};
      for (let i = 0; i < identities.length; i += 1) {
        const key = identities[i]?.category?.slug;
        res[key] = {
          value: identities[i]?.status ? identities[i]?.id_number : '',
          isAdded: identities[i]?.status
        };
      }
      setIds(res);
    }
  }, [status, isFetched, data]);

  const queryClient = useQueryClient();

  if (apiCount.current === 0 && willReload.current === true) {
    willReload.current = false;
    queryClient.invalidateQueries(['user']);
  }

  const bvnMutation = useMutation(handleFetch, {
    onSuccess: () => {
      willReload.current = true;
      apiCount.current -= 1;
      notification({
        title: 'Success',
        message: 'You have successfully added your BVN',
        type: 'success'
      });
    },
    onError: (err) => {
      apiCount.current -= 1;
      notification({
        title: 'BVN Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const ninMutation = useMutation(handleFetch, {
    onSuccess: () => {
      willReload.current = true;
      apiCount.current -= 1;
      notification({
        title: 'Success',
        message: 'You have successfully added your NIN',
        type: 'success'
      });
    },
    onError: (err) => {
      apiCount.current -= 1;
      notification({
        title: 'NIN Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const intpMutation = useMutation(handleFetch, {
    onSuccess: () => {
      willReload.current = true;
      apiCount.current -= 1;
      notification({
        title: 'Success',
        message: 'You have successfully added your Intl Passport No',
        type: 'success'
      });
    },
    onError: (err) => {
      apiCount.current -= 1;
      notification({
        title: 'Intl Passport No Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const dvlMutation = useMutation(handleFetch, {
    onSuccess: () => {
      willReload.current = true;
      apiCount.current -= 1;
      notification({
        title: 'Success',
        message: 'You have successfully added your Driver\'s License Number',
        type: 'success'
      });
    },
    onError: (err) => {
      apiCount.current -= 1;
      notification({
        title: 'Driver\'s License Number Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIds((state) => ({ ...state, [name]: { ...state[name], value } }));
  };

  const handleSubmit = () => {
    if (ids?.bvn?.value && !ids?.bvn?.isAdded) {
      if (ids?.bvn?.value?.length < 11) {
        notification({ title: 'Form Error', message: 'Please, enter a valid BVN', type: 'danger' });
        return;
      }
      apiCount.current += 1;
      bvnMutation.mutateAsync({
        endpoint: 'user', extra: '/set-bvn', auth: true, method: 'POST', body: { bvn: ids?.bvn?.value }
      });
    }
    if (ids?.nin?.value && !ids?.nin?.isAdded) {
      if (ids?.nin?.value?.length < 11) {
        notification({ title: 'Form Error', message: 'Please, enter a valid NIN', type: 'danger' });
        return;
      }
      apiCount.current += 1;
      ninMutation.mutateAsync({
        endpoint: 'user', extra: '/set-nin', auth: true, method: 'POST', body: { nin: ids?.nin?.value }
      });
    }
    if (ids?.intp?.value && !ids?.intp?.isAdded) {
      apiCount.current += 1;
      intpMutation.mutateAsync({
        endpoint: 'user', extra: '/set-intp', auth: true, method: 'POST', body: { id: ids?.intp?.value }
      });
    }
    if (ids?.dvl?.value && !ids?.dvl?.isAdded) {
      apiCount.current += 1;
      dvlMutation.mutateAsync({
        endpoint: 'user', extra: '/set-dvl', auth: true, method: 'POST', body: { id: ids?.dvl?.value }
      });
    }
  };

  const { isLoading: loadingBvn } = bvnMutation;
  const { isLoading: loadingNin } = ninMutation;
  const { isLoading: loadingDvl } = dvlMutation;
  const { isLoading: loadingIntp } = intpMutation;

  return (
    <ViewWrapper header="Verify your identity">
      {(status === 'loading' || loadingBvn || loadingNin || loadingDvl || loadingIntp) && <Loading />}

      <div className="mb-8 sm:mb-16">
        <Link to="/wallet/identities" className="inline-flex items-center hover:underline">
          <MdOutlineArrowBack className="mr-2" />
          Back
        </Link>
        <h2 className="font-bold  text-2xl sm:text-3xl">Verify your identity</h2>
      </div>

      {status === 'success' && (
        <div className="w-full mb-20">
          <h4 className="text-base font-semibold mb-5 sm:mb-10">Enter a valid proof of identity to avoid exposure to losses and fraud.</h4>

          <div className="w-full max-w-lg">
            <div className="">
              <div className="mb-8">
                <TextInput
                  value={ids?.bvn?.isAdded ? maskValue(ids?.bvn?.value) : ids?.bvn?.value || ''}
                  disabled={ids?.bvn?.isAdded}
                  name="bvn"
                  onChange={(e) => /^\d{0,11}$/g.test(e.target.value) && handleChange(e)}
                  label="Enter BVN"
                  placeholder="Bank Verification Number (BVN)"
                />
                <p className="text-xs text-primary text-center mt-1">Dial *565*0# to get your BVN</p>
              </div>

              <p className="text-error font-bold text-2xl my-5 text-center">OR</p>

              <div className="mb-8">
                <TextInput
                  value={ids?.nin?.isAdded ? maskValue(ids?.nin?.value) : ids?.nin?.value || ''}
                  disabled={ids?.nin?.isAdded}
                  name="nin"
                  onChange={(e) => /^\d{0,11}$/g.test(e.target.value) && handleChange(e)}
                  label="Enter NIN"
                  placeholder="National Identity Number"
                />
                <p className="text-xs text-primary text-center mt-1">Dial *346# to get your NIN</p>
              </div>

              <p className="text-error font-bold text-2xl my-5 text-center">OR</p>

              <div className="mb-8">
                <TextInput
                  value={ids?.dvl?.isAdded ? maskValue(ids?.dvl?.value) : ids?.dvl?.value || ''}
                  disabled={ids?.dvl?.isAdded}
                  name="dvl"
                  onChange={handleChange}
                  label="Enter L/No:"
                  placeholder="Driver's License Number"
                />
              </div>

              <p className="text-error font-bold text-2xl my-5 text-center">OR</p>

              <div className="mb-8">
                <TextInput
                  value={ids?.intp?.isAdded ? maskValue(ids?.intp?.value) : ids?.intp?.value || ''}
                  disabled={ids?.intp?.isAdded}
                  name="intp"
                  onChange={handleChange}
                  label="Enter Intl Passport No"
                  placeholder="International Passport Number"
                />
              </div>

              <div className="">
                <Button bgColor="bg-error" className="min-w-max rounded-full" onClick={handleSubmit}>
                  Save changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default VerifyIdentity;
