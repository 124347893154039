import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { formatCurrency } from '../../../utilities/general';
import NoData from '../../../components/commons/NoData';

function PaymentSchedules({ data }) {
  return (
    <div className="w-full">
      {data?.next_payment && (
        <div className="w-full mb-16">
          <h3 className="font-bold text-2xl sm:text-3xl mb-2 sm:mb-5">Payments Schedule</h3>

          <div className="border border-[#FFE7CC] bg-[#FAA74A1F] mt-8 px-8 py-5 rounded-3xl">
            <div className="w-full flex justify-between">
              <div className="">
                <h3 className="font-semibold mb-2 sm:mb-4">Amount Due</h3>
                <h2 className="text-lg font-bold">{formatCurrency(data?.next_payment?.amount_due) || <Skeleton />}</h2>
                <p className="">{`${data?.next_payment?.payout_count || ''} payments`}</p>
              </div>
              <div className="text-center">
                <h3 className="font-semibold mb-2 sm:mb-4">Income from</h3>
                <h2 className="text-lg font-bold">{data?.next_payment?.income_from}</h2>
                <p className="text-primary">{data?.next_payment?.offer_title}</p>
              </div>
              <div className="text-right">
                <h3 className="font-semibold mb-2 sm:mb-4">Due Date</h3>
                <h2 className="text-lg font-bold text-primary">{data?.next_payment?.date}</h2>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="w-full">
        <h3 className="font-bold text-2xl sm:text-3xl mb-2 sm:mb-5">Next payment</h3>
        <p className="text-[#5F7C51] text-base mb-5">
          Your upcoming  payments on monieworx.
        </p>

        <div className="w-full overflow-auto">
          {data?.schedule?.length > 0 ? (
            <table className="w-full table-auto border-separate border-spacing-x-0 border-spacing-y-3 min-w-max text-left">
              <thead>
                <tr className="bg-primary text-white">
                  <th className="px-3 py-3 rounded-tl-2xl">Date</th>
                  <th className="px-3 py-3">Income</th>
                  <th className="px-3 py-3">Deal</th>
                  <th className="px-3 py-3 rounded-tr-2xl">Status</th>
                </tr>
              </thead>
              <tbody>
                {data?.schedule?.map((item) => (
                  <tr className="bg-[#FFE7CC]" key={item?.id || JSON.stringify(item)}>
                    <td className="px-3 py-4 rounded-l-2xl font-bold text-primary">{item?.date}</td>
                    <td className="px-3 py-4">{formatCurrency(item?.income)}</td>
                    <td className="px-3 py-4">
                      <div>
                        <h2 className="font-bold">{item?.issuer}</h2>
                        <p className="text-primary">{item?.offer_title}</p>
                      </div>
                    </td>
                    <td className="px-3 py-4 rounded-r-2xl">{item?.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentSchedules;
