import React from 'react';
import Modal from 'react-responsive-modal';

import SuccessSvg from '../../../assets/svgs/success.svg';

import Button from '../../../components/inputs/Button';

function BankTransferInvestmentNotification({ accountNo = '', onClose = () => {} }) {
  return (
    <Modal open onClose={onClose} center>
      <div className="py-5 text-center max-w-md">
        <img src={SuccessSvg} alt="" className="w-16 h-16 mx-auto mb-2" />
        <h3 className="font-bold text-lg mb-3">Successful</h3>
        <p>Your investment has been successfully reserved.</p>
        <p>Please, make payment to the account number below with 30 minutes to secure your investment.</p>

        <div className="mx-auto inline-block px-10 py-3 mt-3 bg-gray-100">
          <p className="">
            Bank:&nbsp;
            <span className="font-bold">Kuda bank</span>
          </p>
          <p className="">
            Account number:&nbsp;
            <span className="font-bold">{accountNo}</span>
          </p>
        </div>

        <div className="flex justify-center mt-6">
          <Button
            className="rounded-full"
            paddingX="px-10"
            onClick={onClose}
          >
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default BankTransferInvestmentNotification;
