import React from 'react';
import { useNavigate } from 'react-router-dom';
import NoData from '../../../components/commons/NoData';
import Button from '../../../components/inputs/Button';

function Deals({ data = [] }) {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      {data?.map((item) => (
        <div className="border border-[#FFE7CC] bg-[#FAA74A1F] mt-8 px-4 sm:px-8 py-5 rounded-3xl" key={item.id}>
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center space-x-4">
              <div>
                <img src={item?.issuer_logo} className="w-24 h-24 rounded-full bg-gray-200" alt={item?.title} />
              </div>
              <div className="w-[calc(100%-112px)]">
                <div className="flex flex-wrap mb-1 space-x-2 items-center">
                  <h3 className="text-xl font-bold">{item?.max_amount_formatted}</h3>
                  <p className="text-xs">{`(${item?.percent_raised}% raised)`}</p>
                </div>
                <p className="font-bold italic mb-1">
                  {`Earn ${item?.interest_rate}% interest in ${item?.tenure} days`}
                </p>
                <p className="">
                  {item?.title}
                  &nbsp;-&nbsp;
                  {item?.issuer}
                </p>
              </div>
            </div>

            <div className="mt-5 w-full sm:w-auto">
              <Button
                bgColor="bg-primary"
                paddingX="px-6"
                paddingY="py-2"
                fontSize="text-lg"
                className="w-full"
                onClick={() => navigate(`/home/deal-details/${item?.id}`)}
              >
                BUY
              </Button>
            </div>
          </div>
        </div>
      ))}

      {data?.length < 1 && (
        <NoData />
      )}
    </div>
  );
}

export default Deals;
