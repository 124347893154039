import React, { useEffect } from 'react';

import TopBar from '../components/commons/TopBar';

function ViewWrapper({ header = '', children = null } = {}) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `monieworx - ${header}`;
  }, [header]);

  return (
    <div className="w-full lg:pl-72">
      <TopBar header={header} />

      <div className="w-full max-w-screen-2xl min-h-screen px-5 sm:px-10 pt-28">
        <div className="w-full pb-20 relative">
          {children}
        </div>
      </div>
    </div>
  );
}

export default ViewWrapper;
