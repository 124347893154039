import React from 'react';
import Modal from 'react-responsive-modal';

import Button from '../inputs/Button';

function ConfirmPrompt({
  title, message = '', onConfirm, onClose
}) {
  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-lg' }}>
      <div className="px-5 py-3">
        <h3 className="text-lg font-semibold mb-5 border-b">
          {title}
        </h3>
        <div className="mb-5">
          {message}
        </div>
        <div className="flex justify-end space-x-5">
          <Button onClick={onClose} paddingY="py-2">
            No
          </Button>
          <Button bgColor="bg-error" onClick={onConfirm} paddingY="py-2">
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmPrompt;
