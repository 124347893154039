import BanksSvg from '../assets/svgs/shortcuts/banks-sc.svg';
import CardsSvg from '../assets/svgs/shortcuts/cards-sc.svg';
// import CertificatesSvg from '../assets/svgs/shortcuts/certificates-sc.svg';
// import TokensSvg from '../assets/svgs/shortcuts/tokens-sc.svg';
import TransactionsSvg from '../assets/svgs/shortcuts/transactions-sc.svg';
import IdentitySvg from '../assets/svgs/shortcuts/identity-sc.svg';

const walletShortcuts = [
  {
    icon: BanksSvg,
    title: 'Banks',
    link: '/wallet/banks',
    desc: 'Manage your bank accounts details.'
  },
  {
    icon: CardsSvg,
    title: 'Cards',
    link: '/wallet/cards',
    desc: 'Manage your card details.'
  },
  // {
  //   icon: TokensSvg,
  //   title: 'Tokens',
  //   link: '/wallet/tokens',
  //   desc: 'View your digital assets.'
  // },
  // {
  //   icon: CertificatesSvg,
  //   title: 'Certificates',
  //   link: '/wallet/certificates',
  //   desc: 'View your investment certificates.'
  // },
  {
    icon: TransactionsSvg,
    title: 'Transactions',
    link: '/wallet/transactions',
    desc: 'View your transactions history.'
  },
  {
    icon: IdentitySvg,
    title: 'Identity',
    link: '/wallet/identities',
    desc: 'Manage your digital identity.'
  }
];

export default walletShortcuts;
