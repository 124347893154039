import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { useMutation, useQueryClient } from 'react-query';
import { BsBank } from 'react-icons/bs';

import TokenSymbol from '../../../assets/svgs/token-symbol.svg';

import Button from '../../../components/inputs/Button';
import Loading from '../../../components/commons/Loading';
import notification from '../../../utilities/notification';
import handleFetch from '../../../services/api/handleFetch';
import useGetQuery from '../../../hooks/useGetQuery';
import { formatCurrency } from '../../../utilities/general';

function TokenWithdrawal({ tokenBal = 0, onClose = () => {} }) {
  const [amount, setAmount] = useState('');

  const { data } = useGetQuery({ endpoint: 'wallet', queryKey: ['wallet'] });

  const queryClient = useQueryClient();
  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(['wallet']);
      notification({
        title: 'Success',
        message: `You have successfully withdrawn ${formatCurrency(amount, false)} tokens into your wallet`,
        type: 'success'
      });
      onClose();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateForm = () => {
    if (!amount) return 'Enter the amount to withdraw.';
    return null;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const err = validateForm();

    if (err) {
      notification({
        title: 'Form Error',
        message: err,
        type: 'danger'
      });
      return;
    }

    const body = {
      amount: Number(amount)
    };

    submitMutation.mutate({
      endpoint: 'wallet', extra: '/token/withdraw', method: 'POST', body
    });
  };

  const { isLoading } = submitMutation;

  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-lg' }}>
      {isLoading && <Loading />}

      <div className="w-full p-5">
        <h2 className="font-bold text-2xl mb-5">Token Withdrawal</h2>
        <div className="mb-5">
          <p className="text-error">Enter the amount you want to withdraw into your wallet</p>
        </div>

        <div className="w-full mb-5">
          <p className="font-semibold mb-2">{`Amount: max (${formatCurrency(tokenBal, false)} tokens)`}</p>
          <div className="w-full relative">
            <span className="absolute text-xl font-bold py-3.5 px-2">
              <img src={TokenSymbol} className="w-4 h-auto" alt="token" />
            </span>
            <input
              type="number"
              value={amount}
              min={0}
              onChange={(e) => e.target.value <= tokenBal && setAmount(e.target.value)}
              className="w-full text-xl border border-primary outline-none rounded pl-7 pr-3 py-3.5"
            />
          </div>
        </div>

        <div className="w-full mb-5">
          <p className="">Wallet details:</p>
          <div className="flex items-center space-x-3">
            <span className="w-10 h-10 rounded-[18px] bg-[#232323] p-2">
              <BsBank className="text-white w-6 h-auto" />
            </span>
            <div>
              <h2 className="font-bold text-base">monieworx wallet</h2>
              <p className="">{data?.data?.wallet?.account_number}</p>
            </div>
          </div>
        </div>

        <Button
          className="rounded-full mt-10"
          paddingY="py-4"
          paddingX="px-20"
          bgColor="bg-error"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default TokenWithdrawal;
