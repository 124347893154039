import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { TbCircleX } from 'react-icons/tb';
import { TfiArrowCircleRight } from 'react-icons/tfi';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

function CompleteProfile() {
  const [cookies] = useCookies(['data']);

  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState([]);

  useEffect(() => {
    const foo = [
      { verified: !!cookies?.data?.user?.email_verified_at, text: 'Email verification', link: '/profile?tab=security' },
      { verified: !!cookies?.data?.user?.phone_verified_at, text: 'Phone number verification', link: '/profile?tab=security' },
      { verified: cookies?.data?.user?.verification_tier >= 1, text: 'ID verification', link: '/wallet/identities/verify-ids' }
    ];
    setProgress(foo);
  }, [cookies?.data]);

  if (!cookies?.data?.user) {
    return null;
  }

  const percent = (progress.reduce((sum, current) => sum + Number(current?.verified), 0) / 3) * 100;

  if (percent === 100) {
    return null;
  }

  return (
    <>
      <div
        role="presentation"
        onClick={() => setShow(!show)}
        className="w-full bg-[#798A71] text-white rounded-full p-4 mt-10 cursor-pointer"
      >
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <TfiArrowCircleRight className="w-7 h-7" />
            <div className="inline-block">
              <h3 className="text-base font-semibold">Complete your Profile</h3>
              <p className="text-xs">Verify your account on monieworx.</p>
            </div>
          </div>

          <div className="w-[120px] flex space-x-2">
            <div className="w-full">
              <div className="w-full h-5 relative bg-white/50 rounded-full overflow-hidden">
                <div style={{ width: `${percent}%` }} className="h-full bg-white rounded-full absolute top-0 left-0" />
              </div>
            </div>
            <p className="">{`${percent.toFixed(0)}%`}</p>
          </div>
        </div>
      </div>

      <div className={`w-full bg-[#798A71]/20 ${show ? 'h-auto p-4 mt-2' : 'h-0 overflow-hidden'} transition-all`}>
        <div className="w-full mb-3">
          {progress?.map((item) => (
            <Link to={item?.link} key={item?.text}>
              <div className="w-full flex justify-between items-center p-2 hover:bg-white">
                <div className="flex items-center space-x-3">
                  {item?.verified
                    ? <BiCheckCircle className="w-7 h-7 text-success" />
                    : <BiXCircle className="w-7 h-7 text-error" />}
                  <p className="text-sm">{item?.text}</p>
                </div>
                <TfiArrowCircleRight className="w-5 h-5" />
              </div>
            </Link>
          ))}
        </div>
        <div className="w-full flex justify-center">
          <button type="button" onClick={() => setShow(false)}>
            <TbCircleX className="w-10 h-10 rounded-full hover:bg-white" />
          </button>
        </div>
      </div>
    </>
  );
}

export default CompleteProfile;
