import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/svgs/logo.svg';
import Bg from '../../../assets/images/auth-bg.png';
import SignupBanner from '../../../assets/images/sign-up.png';

import AuthFooter from '../../../components/commons/AuthFooter';
import ValidateEmail from './components/ValidateEmail';
import ValidateOtp from './components/ValidateOtp';
import SetNewPassword from './components/SetNewPassword';

function ForgetPassword() {
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'monieworx - Forget Password';
  }, []);

  return (
    <div style={{ background: `no-repeat url(${Bg})` }} className="relative w-full h-full min-h-screen !bg-cover px-10 pt-24 pb-5">
      <div className="w-full flex min-h-[calc(100vh-300px)] max-w-6xl m-auto overflow-hidden mb-20 bg-white rounded-b-3xl border border-primary">
        <div className="w-full flex">
          <div className="w-full mdx2:w-2/3 px-8 py-10">
            <Link to="/">
              <img
                alt="Logo"
                src={Logo}
                className="w-64 h-auto mb-5"
              />
            </Link>

            <div className="w-full mt-20">
              {pageIndex === 0 && <ValidateEmail onNext={() => setPageIndex(1)} />}
              {pageIndex === 1 && <ValidateOtp onNext={() => setPageIndex(2)} />}
              {pageIndex === 2 && <SetNewPassword />}
            </div>
          </div>

          <div style={{ background: `no-repeat url(${SignupBanner}) center` }} className="!bg-cover hidden mdx2:block w-1/3" />
        </div>
      </div>

      <AuthFooter />
    </div>
  );
}

export default ForgetPassword;
