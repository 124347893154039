import React from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import VideoTn from '../../../assets/images/video-tn.png';
import NotificationItem from './NotificationItem';

function NonFinancial({
  handleShowVGuide, data, status, error
}) {
  const [cookie] = useCookies(['data']);
  const { user } = cookie?.data || {};

  if (status === 'loading') {
    return (
      <div className="w-full py-10 text-center">
        Loading notifications...
      </div>
    );
  }

  if (status === 'success') {
    const { non_financial: nonFinancial } = data?.data?.data || {};

    return (
      <div className="w-full">
        <div className="w-full">
          {nonFinancial?.map((item) => (
            <div key={item?.id} className="w-full">
              <NotificationItem data={item} />
            </div>
          ))}
        </div>

        <div className="mb-4">
          <h3 className="text-lg text-primary font-bold">Setup Guide</h3>
          <p className="text-xs text-primary">Get started on monieworx</p>
        </div>

        <div
          role="presentation"
          onClick={handleShowVGuide}
          className="w-full flex border border-[#FFE7CC] rounded-lg overflow-hidden cursor-pointer hover:bg-gray-50"
        >
          <div className="w-[85px] h-[70px]">
            <img src={VideoTn} alt="video thumbnail" className="w-[85px] h-[70px]" />
          </div>
          <div className="w-[calc(100%-85px)] flex items-center px-4">
            <div className="">
              <h3 className="text-sm sm:text-base font-bold">Welcome to monieworx</h3>
              <p className="text-xs">
                We are making monie and investing work better for the 99%...&nbsp;
                <b>Watch video</b>
              </p>
            </div>
          </div>
        </div>

        <div className="w-full py-3">
          {!user?.phone_verified_at && (
            <Link to="/profile?tab=security">
              <div className="flex w-full justify-between items-center border-b py-3">
                <div className="">
                  <h4 className="text-sm font-bold mb-2">Verify your phone number</h4>
                  <p className="text-xs">Let&apos;s start by making sure we can reach you</p>
                </div>
                <div className="">
                  <HiOutlineChevronRight className="" />
                </div>
              </div>
            </Link>
          )}
          {!user?.email_verified_at && (
            <Link to="/profile?tab=security">
              <div className="flex w-full justify-between items-center border-b py-3">
                <div className="">
                  <h4 className="text-sm font-bold mb-2">Verify your email address</h4>
                  <p className="text-xs">Let&apos;s start by making sure we can reach you</p>
                </div>
                <div className="">
                  <HiOutlineChevronRight className="" />
                </div>
              </div>
            </Link>
          )}
          {user?.verification_tier < 1 && (
            <Link to="/wallet/identities/verify-ids">
              <div className="flex w-full justify-between items-center border-b py-3">
                <div className="">
                  <h4 className="text-sm font-bold mb-2">Verify your Identity</h4>
                  <p className="text-xs">Let&apos;s start by knowing who you are.</p>
                </div>
                <div className="">
                  <HiOutlineChevronRight className="" />
                </div>
              </div>
            </Link>
          )}
          {user?.balance === '0.00' && (
            <Link to="/wallet">
              <div className="flex w-full justify-between items-center border-b py-3">
                <div className="">
                  <h4 className="text-sm font-bold mb-2">Fund your account</h4>
                  <p className="text-xs">Add monie to your account and start investing</p>
                </div>
                <div className="">
                  <HiOutlineChevronRight className="" />
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="w-full py-10 text-center">
        {String(error)}
      </div>
    );
  }
}

export default NonFinancial;
