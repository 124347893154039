import React from 'react';

import NoData from '../NoData';
import NotificationItem from './NotificationItem';

function Financials({ data, status, error }) {
  if (status === 'loading') {
    return (
      <div className="w-full py-10 text-center">
        Loading notifications...
      </div>
    );
  }

  if (status === 'success') {
    const { financial } = data?.data?.data || {};

    return (
      <div className="w-full">
        {financial?.map((item) => (
          <div key={item?.id} className="w-full">
            <NotificationItem data={item} />
          </div>
        ))}

        {financial?.length < 1 && (
          <div className="w-full">
            <NoData />
          </div>
        )}
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="w-full py-10 text-center">
        {String(error)}
      </div>
    );
  }
}

export default Financials;
