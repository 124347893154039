import Visa from '../assets/svgs/visa-logo.svg';
import Mastercard from '../assets/svgs/mastercard-logo.svg';
import Verve from '../assets/svgs/verve-logo.svg';

const cardLogos = {
  visa: Visa,
  mastercard: Mastercard,
  verve: Verve
};

export default cardLogos;
