import React from 'react';

import { ReactNotifications } from 'react-notifications-component';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import 'react-loading-skeleton/dist/skeleton.css';
import 'react-responsive-modal/styles.css';
import './assets/styles/styles.scss';
import './assets/styles/inputs.scss';

import ThemeWrapper from './wrappers/ThemeWrapper';
import NavigableLayout from './wrappers/NavigableLayout';

import Login from './pages/auth/login';
import Signup from './pages/auth/sign-up';
import ForgetPassword from './pages/auth/forget-password';

import Home from './pages/home';
import DealDetails from './pages/home/DealDetails';

import Account from './pages/profile';

import Portfolio from './pages/portfolio';
import InvestmentDetails from './pages/portfolio/InvestmentDetails';
import Income from './pages/portfolio/Income';

import Wallet from './pages/wallet';
import Banks from './pages/wallet/Banks';
import Cards from './pages/wallet/Cards';
import Certificates from './pages/wallet/Certificates';
import Tokens from './pages/wallet/Tokens';
import Transactions from './pages/wallet/Transactions';
import AddMonie from './pages/wallet/AddMonie';
import AddBankAccount from './pages/wallet/AddBankAccount';
import Identity from './pages/wallet/Identities';
import VerifyIdentity from './pages/wallet/VerifyIdentity';

import Community from './pages/community';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });

  return (
    <ThemeWrapper>
      <ReactNotifications />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/sign-up/:refCode" element={<Signup />} />
            <Route path="/forget-password" element={<ForgetPassword />} />

            <Route path="/home" element={<NavigableLayout><Home /></NavigableLayout>} />
            <Route path="/home/deal-details/:id" element={<NavigableLayout><DealDetails /></NavigableLayout>} />

            <Route path="/profile" element={<NavigableLayout><Account /></NavigableLayout>} />

            <Route path="/portfolio" element={<NavigableLayout><Portfolio /></NavigableLayout>} />
            <Route path="/portfolio/investment-details/:id" element={<NavigableLayout><InvestmentDetails /></NavigableLayout>} />
            <Route path="/portfolio/income" element={<NavigableLayout><Income /></NavigableLayout>} />

            <Route path="/wallet" element={<NavigableLayout><Wallet /></NavigableLayout>} />
            <Route path="/wallet/add-monie" element={<NavigableLayout><AddMonie /></NavigableLayout>} />
            <Route path="/wallet/banks" element={<NavigableLayout><Banks /></NavigableLayout>} />
            <Route path="/wallet/banks/add-bank-account" element={<NavigableLayout><AddBankAccount /></NavigableLayout>} />
            <Route path="/wallet/cards" element={<NavigableLayout><Cards /></NavigableLayout>} />
            <Route path="/wallet/certificates" element={<NavigableLayout><Certificates /></NavigableLayout>} />
            <Route path="/wallet/tokens" element={<NavigableLayout><Tokens /></NavigableLayout>} />
            <Route path="/wallet/transactions" element={<NavigableLayout><Transactions /></NavigableLayout>} />
            <Route path="/wallet/identities" element={<NavigableLayout><Identity /></NavigableLayout>} />
            <Route path="/wallet/identities/verify-ids" element={<NavigableLayout><VerifyIdentity /></NavigableLayout>} />

            <Route path="/community" element={<NavigableLayout><Community /></NavigableLayout>} />

            <Route path="*" element={<center>Page not found</center>} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeWrapper>
  );
}

export default App;
