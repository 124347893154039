import React from 'react';

import Skeleton from 'react-loading-skeleton';

import PortfolioIcon from '../../../assets/svgs/portfolio-icon.svg';
import IncomeIcon from '../../../assets/svgs/income-icon.svg';

export function Card({
  icon, title, amount, bgColor
}) {
  return (
    <div style={{ backgroundColor: bgColor }} className="w-full rounded-xl px-7 py-5">
      <div className="flex items-center space-x-3 mb-2 sm:mb-4">
        <img src={icon} className="w-8 h-auto" alt={title} />
        <h4 className="text-lg font-bold">{title}</h4>
      </div>
      <div className="flex items-center justify-between">
        <h4 className="text-3xl sm:text-4xl font-bold">{amount ?? <Skeleton />}</h4>
      </div>
    </div>
  );
}

function PerformanceCards({ data }) {
  return (
    <div className="flex flex-wrap -mx-3">
      <div className="w-full md:w-1/2 p-3">
        <Card icon={PortfolioIcon} bgColor="#FFE7CC" title="Balance" amount={data?.account_balance} />
      </div>
      <div className="w-full md:w-1/2 p-3">
        <Card icon={IncomeIcon} bgColor="#E0FFB8" title="Withdrawals" amount={data?.withdrawals} />
      </div>
    </div>
  );
}

export default PerformanceCards;
