import React from 'react';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

function AboutTab() {
  return (
    <div className="w-full">
      <ul className="w-full">
        <a href="https://monieworx.com/terms-of-service" target="_blank" rel="noreferrer">
          <li className="border-b py-4 mb-5 hover:cursor-pointer hover:bg-white/40">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base">Legal</h3>
              <div>
                <MdOutlineArrowForwardIos className="w-7 h-auto" />
              </div>
            </div>
          </li>
        </a>

        <a href="https://monieworx.com/privacy-policy" target="_blank" rel="noreferrer">
          <li className="border-b py-4 mb-5 hover:cursor-pointer hover:bg-white/40">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base">Privacy Policy</h3>
              <div>
                <MdOutlineArrowForwardIos className="w-7 h-auto" />
              </div>
            </div>
          </li>
        </a>

        <a href="https://monieworx.com/contact" target="_blank" rel="noreferrer">
          <li className="border-b py-4 mb-5 hover:cursor-pointer hover:bg-white/40">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base">Support</h3>
              <div>
                <MdOutlineArrowForwardIos className="w-7 h-auto" />
              </div>
            </div>
          </li>
        </a>

        <a href="https://monieworx.com/about" target="_blank" rel="noreferrer">
          <li className="border-b py-4 mb-5 hover:cursor-pointer hover:bg-white/40">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base">About monieworx</h3>
              <div>
                <MdOutlineArrowForwardIos className="w-7 h-auto" />
              </div>
            </div>
          </li>
        </a>

      </ul>
    </div>
  );
}

export default AboutTab;
