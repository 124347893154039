import React from 'react';

function CheckBox({
  name, value, checked, onChange = () => {}, label = 'Check Box', type = 'checkbox', className = ''
}) {
  return (
    <div className={`${className} my-custom-checkbox`}>
      <input
        type={type}
        name={name}
        value={value}
        id={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
}

export default CheckBox;
