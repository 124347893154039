import React, { useEffect, useState } from 'react';
import Select from 'react-select';

function SelectInput({
  className, name, label, value, onChange, disabled, options, error,
  readOnly, onBlur, onKeyDown, multiple, placeholder, inputBg = 'bg-white'
}) {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (typeof options?.[0] === 'object' && !Array.isArray(options?.[0]) && options?.[0] !== null) {
      setList(options);
    } else {
      const newList = options?.map((item) => ({ value: item, label: item }));
      setList(newList);
    }
  }, [options]);

  return (
    <div className={`${className || ''} relative`}>
      <label htmlFor={name} className={`text-xs absolute ${inputBg} text-primary px-2 -top-2 left-4 z-10`}>
        {label}
      </label>

      <Select
        classNames={{
          control: () => `border !rounded w-full h-[55.5px] pt-2 pb-2 px-0.5 ${inputBg
          } text-gray-700 leading-tight !border-primary !outline-none !shadow-none`
        }}
        name={name}
        id={name}
        value={value}
        isMulti={multiple}
        isSearchable
        onChange={onChange}
        isDisabled={disabled}
        options={list || []}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabled}
        onKeyDown={onKeyDown}
        placeholder={placeholder || ''}
      />

      {error ? <p className="text-error text-sm">{error}</p> : null}
    </div>
  );
}

export default SelectInput;
