import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { BsPlusLg } from 'react-icons/bs';
import { ImArrowDownLeft2 } from 'react-icons/im';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import BalanceImg from '../../../assets/svgs/balance-icon.svg';

import Button from '../../../components/inputs/Button';
// import PeriodSelect from '../../../components/inputs/PeriodSelect';
// import TokenBalance from './TokenBalance';
import WalletWithdrawal from './WalletWithdrawal';
import VerificationError from '../../../components/commons/VerificationError';
import NoBankAccount from './NoBankAccount';
import { formatCurrency } from '../../../utilities/general';

function AccountBalanceCards({ data }) {
  const navigate = useNavigate();
  const [cookie] = useCookies(['data']);

  const [showWithdrawal, setShowWithdrawal] = useState(false);
  const [showAddBankMessage, setShowAddBankMessage] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState(false);

  const accountBal = Number(data?.wallet?.account_balance);

  const handleAddMonie = () => {
    if (cookie?.data?.user?.verification_tier > 0) {
      navigate('/wallet/add-monie');
    } else {
      setShowVerificationError(true);
    }
  };

  const handleWithdrawal = () => {
    if (data?.bank_accounts?.length > 1) {
      setShowWithdrawal(true);
    } else {
      setShowAddBankMessage(true);
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="flex flex-wrap -m-4">
          <div className="w-full max-w-4xl 2xl:w-1/2 px-4 py-6">
            {/* <div className="flex items-center mb-5 sm:mb-10">
              <h4 className="text-base font-semibold mr-4">Your monieworx balance:</h4>
              <PeriodSelect />
            </div> */}

            <div className="w-full flex flex-wrap items-center">
              <div className="bg-[#7C7C7C26] px-7 py-10 rounded-3xl w-full md:w-7/12">
                <div className="w-full">
                  <div className="flex items-center space-x-3 mb-5 sm:mb-10">
                    <img src={BalanceImg} className="w-10 h-auto" alt="account" />
                    <div className="text-primary">
                      <h4 className="text-base font-bold">{(`${cookie?.data?.user?.f_name} ${cookie?.data?.user?.l_name}`).toUpperCase()}</h4>
                      <p className="text-sm">{`${data?.wallet?.bank ?? ''} - ${data?.wallet?.account_number ?? ''}`}</p>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="text-3xl sm:text-4xl font-bold mb-2 sm:mb-4">
                        {formatCurrency(data?.wallet?.account_balance) ?? <Skeleton baseColor="#7C7C7C26" />}
                      </h4>
                      <p className="text-sm font-bold">Available Balance</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-5/12 md:pl-10 flex flex-wrap md:block">
                <Button onClick={handleAddMonie} fontSize="text-base sm:text-lg" className="mt-4 sm:mt-7 mr-5 min-w-max">
                  <BsPlusLg className="mr-3 w-6 h-auto" />
                  Add Monie
                </Button>
                <Button
                  fontSize="text-base sm:text-lg"
                  bgColor="bg-error"
                  className="mt-4 sm:mt-7 min-w-max"
                  onClick={() => !!accountBal && handleWithdrawal()}
                >
                  <ImArrowDownLeft2 className="mr-3 w-6 h-auto" />
                  Withdraw
                </Button>
              </div>
            </div>
          </div>

          {/* <div className="w-full max-w-4xl 2xl:w-1/2 px-4 py-6">
            <TokenBalance data={data?.tokens} />
          </div> */}
        </div>
      </div>

      {showWithdrawal && (
        <WalletWithdrawal accountBal={accountBal} onClose={() => setShowWithdrawal(false)} />
      )}

      {showAddBankMessage && (
        <NoBankAccount onClose={() => setShowAddBankMessage(false)} />
      )}

      {showVerificationError && (
        <VerificationError onClose={() => setShowVerificationError(false)} />
      )}
    </>
  );
}

export default AccountBalanceCards;
