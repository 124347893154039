import React from 'react';

import { IoIosHome } from 'react-icons/io';
import { MdPersonOutline, MdOutlineAccountBalanceWallet } from 'react-icons/md';
// import { FaRegCommentDots } from 'react-icons/fa';
// import { TbChartDots2 } from 'react-icons/tb';

const sidebarMenu = () => [
  {
    section: 'QUICK ACCESS',
    menu: [
      { title: 'Home', link: '/home', icon: <IoIosHome className="icon mr-2.5" /> },
      { title: 'Profile', link: '/profile', icon: <MdPersonOutline className="icon mr-2.5" /> }
    ]
  },
  // {
  //   section: 'PORTFOLIO',
  //   menu: [
  //     {
  //       title: 'Portfolio',
  //       link: '/portfolio',
  //       icon: <TbChartDots2 className="icon mr-2.5" />,
  //       children: [
  //         { title: 'Income', link: '/portfolio/income' }
  //         // { title: 'Goals', link: '/portfolio/goals' }
  //       ]
  //     }
  //   ]
  // },
  {
    section: 'WALLET',
    menu: [
      {
        title: 'Wallet',
        link: '/wallet',
        icon: <MdOutlineAccountBalanceWallet className="icon mr-2.5" />,
        children: [
          { title: 'Banks', link: '/wallet/banks' },
          // { title: 'Tokens', link: '/wallet/tokens' },
          { title: 'Cards', link: '/wallet/cards' },
          // { title: 'Certificates', link: '/wallet/certificates' },
          { title: 'Transactions', link: '/wallet/transactions' },
          { title: 'Identity', link: '/wallet/identities' }
        ]
      }
    ]
  // },
  // {
  //   section: 'COMMUNITY',
  //   menu: [
  //     {
  //       title: 'Community',
  //       link: '/community',
  //       icon: <FaRegCommentDots className="icon mr-2.5" />
  //       // children: [
  //       //   { title: 'Starter Tips', link: '/community/starter-tips' },
  //       //   { title: 'Similar Interests', link: '/community/similar-interests' }
  //       // ]
  //     }
  //   ]
  }
];

export default sidebarMenu;
