import React, { useEffect } from 'react';

import useGetQuery from '../../hooks/useGetQuery';
import notification from '../../utilities/notification';

import Loading from '../../components/commons/Loading';
import ViewWrapper from '../../wrappers/ViewWrapper';
import AccountBalanceCards from './components/AccountBalanceCards';
import Shortcuts from './components/Shortcuts';

function index() {
  const { data, status, error } = useGetQuery({ endpoint: 'wallet', queryKey: ['wallet'] });

  useEffect(() => {
    if (status === 'error') {
      notification({
        title: 'Error',
        message: error?.toString() || 'Something went wrong while fetching data',
        type: 'danger'
      });
    }
  }, [status]);

  return (
    <ViewWrapper header="Wallet">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Wallet</h2>
      </div>

      <div className="w-full mb-10 sm:mb-20">
        <AccountBalanceCards data={data?.data} />
      </div>

      <div className="w-full max-w-5xl">
        <h2 className="font-bold text-2xl sm:text-3xl mb-3 sm:mb-5">Shortcuts</h2>
        <p className="text-[#5F7C51] text-base mb-5">
          Everything you can do within your monieworx wallet
        </p>

        <Shortcuts />
      </div>
    </ViewWrapper>
  );
}

export default index;
