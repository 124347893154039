import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { useMutation, useQueryClient } from 'react-query';

import Button from '../../../components/inputs/Button';
import Loading from '../../../components/commons/Loading';
import notification from '../../../utilities/notification';
import handleFetch from '../../../services/api/handleFetch';
import SelectInput from '../../../components/inputs/Select';
import { validateField } from '../../../utilities/validator';

function SetAutoDebit({ onClose = () => {}, primaryCard = {} }) {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const queryClient = useQueryClient();
  const submitMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['get-cards']);
      notification({
        title: 'Success',
        message: res?.msg || 'You have successfully setup auto debit on your account.',
        type: 'success'
      });
      onClose();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleChange = (val, type = 'input', inputName = '') => {
    if (type === 'input') {
      const { value, name } = val.target;
      const error = validateField(value, 'isRequired, isLessThan1000');
      setForm((state) => ({ ...state, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      const error = validateField(val, 'isRequired');
      setForm((state) => ({ ...state, [inputName]: val }));
      setErrors((prev) => ({ ...prev, [inputName]: error }));
    }
  };

  const validateForm = () => {
    const { amount, moment } = form;

    if (!amount) setErrors((prev) => ({ ...prev, amount: 'This field is required' }));
    if (amount < 1000) setErrors((prev) => ({ ...prev, amount: 'Minimum value is ₦1,000' }));
    if (!moment?.value) setErrors((prev) => ({ ...prev, moment: 'This field is required' }));
    if (moment?.value < 1 || moment?.value > 28) {
      setErrors((prev) => ({ ...prev, moment: 'The value must be between 1 and 28 inclusive.' }));
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    validateForm();

    if (Object.values(errors).some((item) => item !== null)) {
      return;
    }

    const body = {
      amount: Number(form.amount),
      moment: Number(form.moment.value)
    };

    submitMutation.mutate({
      endpoint: 'wallet', extra: '/autodebit', method: 'POST', body
    });
  };

  const { isLoading } = submitMutation;

  console.log(primaryCard);
  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-lg !overflow-visible' }}>
      {isLoading && <Loading />}

      <div className="w-full p-5">
        <h2 className="font-bold text-2xl mb-5">Set Auto Debit</h2>
        <div className="mb-5">
          <p className="text-primary text-xl font-semibold mb-1">
            Your primary card will be debited monthly for the chosen amount
          </p>
        </div>

        <div className="w-full mb-7">
          <div className="w-full relative mb-7">
            <p className="text-xs absolute bg-white text-primary px-2 -top-2 left-4">Amount</p>
            <span className="absolute text-xl font-bold py-3.5 px-2">₦</span>
            <input
              type="number"
              value={form?.amount || ''}
              name="amount"
              min={0}
              onChange={handleChange}
              className="w-full text-xl border border-primary outline-none rounded pl-7 pr-3 py-3.5"
            />
            {errors?.amount ? <p className="text-error text-sm">{errors?.amount}</p> : null}
          </div>

          <SelectInput
            label="Day of the month (1 - 28)"
            value={form?.moment}
            onChange={(val) => handleChange(val, 'select', 'moment')}
            options={new Array(28).fill(0).map((_, index) => ({ label: index + 1, value: index + 1 }))}
            error={errors?.moment}
          />

          <p className="text-sm text-gray-300 mt-3">
            Primary Card:
            <span>{` **** **** **** ${primaryCard?.last_four_digits || '****'}`}</span>
            <span className="uppercase">{` (${primaryCard.card_type})`}</span>
          </p>
        </div>

        <Button
          className="rounded-full"
          paddingY="py-4"
          paddingX="px-20"
          bgColor="bg-error"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default SetAutoDebit;
