import React from 'react';

const options = ['Financial', 'Non-Financial'];

function Tabs({ currentTab, setCurrentTab }) {
  return (
    <div className="w-full">
      <div className="w-full flex">
        {options.map((item, index) => (
          <div className="w-1/2" key={item}>
            <button
              className={`w-full text-sm font-bold relative ${currentTab === index ? 'after:bg-primary' : ''
              } after:absolute after:w-[50px] after:h-1 after:-bottom-2 after:-translate-x-1/2 after:left-1/2`}
              onClick={() => setCurrentTab(index)}
              type="button"
            >
              {item}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tabs;
