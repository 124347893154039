import React from 'react';

import ViewWrapper from '../../wrappers/ViewWrapper';
import useGetQuery from '../../hooks/useGetQuery';
import IncomeStat from './components/IncomeStat';
import PaymentSchedules from './components/PaymentSchedules';
import Loading from '../../components/commons/Loading';

function Income() {
  const { data, status, error } = useGetQuery({ endpoint: 'portfolio', extra: '/income-report', queryKey: ['income-report'] });

  return (
    <ViewWrapper header="Income">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Income</h2>
      </div>

      {status === 'success' && (
        <div className="w-full max-w-3xl">
          <IncomeStat data={data?.data} />
          <PaymentSchedules data={data?.data} />
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default Income;
