import React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import Sidebar from '../components/commons/Sidebar';
import useSocket from '../hooks/socketHook';

export default function NavigableLayout({ children }) {
  const queryClient = useQueryClient();
  const [cookies, setCookie] = useCookies(['data']);

  if (!cookies?.data?.token) {
    return <Navigate to="/" replace />;
  }

  useSocket({
    type: 'EMAIL_VERIFIED',
    callBack: () => {
      const { user, token } = cookies.data;
      setCookie('data', { token, user: { ...user, email_verified_at: new Date().toString() } });
    }
  });

  const refetchWalletDetails = () => {
    queryClient.invalidateQueries(['wallet']);
    queryClient.invalidateQueries(['user']);
  };

  useSocket({
    type: 'WALLET_FUNDING',
    callBack: () => refetchWalletDetails()
  });

  useSocket({
    type: 'WALLET_WITHDRAWAL',
    callBack: () => refetchWalletDetails()
  });

  return (
    <div className="w-full h-full min-h-screen relative">
      <Sidebar />
      {children}
    </div>
  );
}
