import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/inputs/Button';
import { truncateText } from '../../../utilities/general';
import ChangePassword from './ChangePassword';
import VerifyEmail from './VerifyEmail';
import VerifyPhone from './VerifyPhone';

function SecurityTab({ data }) {
  const navigate = useNavigate();

  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  return (
    <>
      <div className="w-full">
        <ul className="w-full">
          <li className="border-b py-2 mb-5">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base mr-2">Change Password</h3>
              <div>
                <Button
                  bgColor="bg-error/10"
                  className="rounded-full min-w-max"
                  textColor="text-error"
                  paddingX="px-7"
                  onClick={() => setShowChangePassword(true)}
                >
                  Change Password
                </Button>
              </div>
            </div>
          </li>

          <li className="border-b py-2 mb-5">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base mr-2">Email Verification</h3>
              <div>
                <Button
                  bgColor="bg-[#C7FFAB]"
                  textColor="text-[#5E9942]"
                  paddingX="px-7"
                  onClick={() => setShowVerifyEmail(true)}
                  disabled={!!data?.email_verified_at}
                  className="rounded-full min-w-max"
                >
                  {data?.email_verified_at ? truncateText(data?.email, 15) : 'Verify Email'}
                </Button>
              </div>
            </div>
          </li>

          <li className="border-b py-2 mb-5">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base mr-2">Phone Verification</h3>
              <div>
                <Button
                  bgColor="bg-[#C7FFAB]"
                  textColor="text-[#5E9942]"
                  paddingX="px-7"
                  onClick={() => setShowVerifyPhone(true)}
                  disabled={!!data?.phone_verified_at}
                  className="rounded-full min-w-max"
                >
                  {data?.phone_verified_at ? truncateText(data?.phone, 15) : 'Verify Phone'}
                </Button>
              </div>
            </div>
          </li>

          <li className="border-b py-2 mb-5">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-base mr-2">Identity Verification</h3>
              <div>
                <Button
                  bgColor="bg-[#ABEBD2]"
                  textColor="text-[#0B5E67]"
                  paddingX="px-7"
                  onClick={() => navigate('/wallet/identities/verify-ids')}
                  className="rounded-full min-w-max"
                >
                  {data?.verification_tier > 0 ? 'Verified!' : 'Verify identity'}
                </Button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      {showChangePassword && (
        <ChangePassword
          onClose={() => setShowChangePassword(false)}
          phone={data?.phone}
        />
      )}

      {showVerifyEmail && (
        <VerifyEmail
          onClose={() => setShowVerifyEmail(false)}
          email={data?.email}
        />
      )}

      {showVerifyPhone && (
        <VerifyPhone
          onClose={() => setShowVerifyPhone(false)}
          phone={data?.phone}
        />
      )}
    </>
  );
}

export default SecurityTab;
