import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { usePaystackPayment } from 'react-paystack';

import Button from '../../../components/inputs/Button';
import Loading from '../../../components/commons/Loading';
import handleFetch from '../../../services/api/handleFetch';
import notification from '../../../utilities/notification';
import ConfirmPrompt from '../../../components/commons/ConfirmPrompt';

import useGetQuery from '../../../hooks/useGetQuery';

import AddCardButton from './AddCardButton';
import SetAutoDebit from './SetAutoDebit';
import NoCard from './NoCard';
import Card from './Card';

function CardsSlide({ data = {} }) {
  const location = useLocation();
  const query = queryString.parse(location.search);

  const [showAutoDebitForm, setShowAutoDebitForm] = useState(false);
  const [showNoCardModal, setShowNoCardModal] = useState(false);
  const [showCancelAutoDebitPrompt, setShowCancelAutoDebitPrompt] = useState(false);
  const [addCardConfig, setAddCardConfig] = useState({});

  const pendingAutoDebit = useRef(false);
  const setAutoDebitBtnRef = useRef(null);
  const addCardBtnRef = useRef(null);

  const { bank_cards: bankCards, tokens } = data;

  const {
    data: cardProvider, status, error, isFetched, isRefetching
  } = useGetQuery({
    endpoint: 'cards', extra: '/provider-info', queryKey: ['card-provider-info']
  });

  const [cookie] = useCookies(['data', 'ac']);

  useEffect(() => {
    if (status === 'success' || isFetched) {
      setAddCardConfig({
        amount: 5000,
        email: cookie?.data?.user?.email,
        reference: cardProvider?.data?.reference,
        publicKey: cardProvider?.data?.public_key
      });
    } else if (status === 'error') {
      notification({
        title: 'Error',
        message: error?.toString() || 'Something went wrong while fetching data',
        type: 'danger'
      });
    }
  }, [status, isRefetching]);

  const initializePayment = usePaystackPayment(addCardConfig);

  const queryClient = useQueryClient();
  const cancelAutoDebitMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['get-cards']);
      notification({
        title: 'Success',
        message: res?.msg || 'You have successfully cancelled auto debit on your account.',
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleAddCardSuccess = () => {
    if (pendingAutoDebit.current) {
      setShowAutoDebitForm(true);
      pendingAutoDebit.current = false;
    }
  };

  useEffect(() => {
    if (cookie?.ac?.status === 'requesting') {
      addCardBtnRef.current.click();
    }
  }, [cookie?.ac]);

  useEffect(() => {
    if (Number(query?._ad) === 1) {
      window.history.pushState({}, 'monieworx - Cards', location.pathname);
      setAutoDebitBtnRef.current.click();
    }
  }, [query?._ad]);

  const handleAddCard = () => {
    setShowNoCardModal(false);
    pendingAutoDebit.current = true;
    addCardBtnRef.current.click();
  };

  const handleCloseAutoDebitForm = () => {
    window.history.pushState({}, 'monieworx - Cards', location.pathname);
    setShowAutoDebitForm(false);
  };

  const handleCancelAutoDebit = () => {
    setShowCancelAutoDebitPrompt(false);
    cancelAutoDebitMutation.mutate({ endpoint: 'wallet', extra: '/autodebit/cancel', method: 'POST' });
  };

  const { isLoading } = cancelAutoDebitMutation;

  return (
    <>
      {(status === 'loading' || isLoading) && <Loading />}

      <div className="w-full">
        <div className="w-full flex flex-wrap-reverse">
          <div className="w-full md:w-[calc(100%-200px)]">
            <div className="w-full slide-wrapper flex space-x-5 overflow-auto">
              {bankCards?.map((item) => (
                <Card data={item} key={item?.id} />
              ))}

              {bankCards?.length < 1 && (
                <h3 className="py-4 text-xl text-gray-400">
                  You do not have any card yet.
                </h3>
              )}
            </div>
          </div>

          <div className="w-[200px] bg-white mb-5 md:mb-0 px-5 flex flex-wrap items-center">
            <div className="text-center">
              <AddCardButton
                btnRef={addCardBtnRef}
                initializePayment={initializePayment}
                onSuccess={handleAddCardSuccess}
              />
              {tokens?.auto_debit ? (
                <Button
                  onClick={() => setShowCancelAutoDebitPrompt(true)}
                  bgColor="bg-error"
                  className="w-full min-w-max mb-2"
                  fontSize="text-base"
                >
                  Cancel Auto Debit
                </Button>
              ) : (
                <Button
                  btnRef={setAutoDebitBtnRef}
                  onClick={() => (bankCards?.length < 1 ? setShowNoCardModal(true) : setShowAutoDebitForm(true))}
                  className="w-full min-w-max mb-2"
                  fontSize="text-base"
                >
                  Set Auto Debit
                </Button>
              )}
              <p className="text-gray-400">{tokens?.auto_debit}</p>
            </div>
          </div>
        </div>
      </div>

      {showNoCardModal && <NoCard onClose={() => setShowNoCardModal(false)} onAddCard={handleAddCard} />}
      {showAutoDebitForm && (
        <SetAutoDebit onClose={handleCloseAutoDebitForm} primaryCard={data?.bank_cards?.find((item) => item.primary)} />
      )}
      {showCancelAutoDebitPrompt && (
        <ConfirmPrompt
          onClose={() => setShowCancelAutoDebitPrompt(false)}
          title="Cancel Auto Debit"
          message="Are you sure you want to can Auto Debit on your account?"
          onConfirm={handleCancelAutoDebit}
        />
      )}
    </>
  );
}

export default CardsSlide;
