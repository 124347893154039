import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useCookies } from 'react-cookie';

import handleFetch from '../../../services/api/handleFetch';
import Avatar from '../../../assets/images/avatar.png';
import Loading from '../../../components/commons/Loading';
import Button from '../../../components/inputs/Button';
import notification from '../../../utilities/notification';

function CreateTag() {
  const [cookie, setCookie] = useCookies(['data']);
  const [tag, setTag] = useState('');

  const queryClient = useQueryClient();
  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      const { user, token } = cookie.data;

      setCookie('data', { token, user: { ...user, monie_tag: tag } });
      queryClient.invalidateQueries(['community']);
      notification({
        title: 'Success',
        message: 'You have successfully setup your monieworx tag.',
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleSubmit = () => {
    if (!tag) {
      notification({
        title: 'Form Error',
        message: 'Please enter your monieworx tag',
        type: 'danger'
      });
      return;
    }

    submitMutation.mutate({
      endpoint: 'user', extra: '/set-monie-tag', method: 'PUT', body: { tag }
    });
  };

  const { isLoading } = submitMutation;

  return (
    <>
      {isLoading && <Loading />}

      <div className="w-full">
        <div className="w-full mb-16">
          <h3 className="text-xl font-semibold text-primary mb-3">Welcome!</h3>
          <h3 className="text-xl font-semibold text-primary">Create your monietag to join the monieworx community.</h3>
        </div>

        <div className="flex items-center space-x-4 mb-5 sm:mb-10">
          <div>
            <img src={Avatar} className="w-14 h-auto" alt="investor" />
          </div>
          <div>
            <input
              type="text"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              className="border border-primary outline-none px-5 py-3 rounded-lg"
              placeholder="Create your monietag"
            />
          </div>
        </div>

        <p className="">Choose a name as unique as you. monietags cannot be changed once created.</p>

        <Button
          className="rounded-full mt-10"
          paddingY="py-4"
          paddingX="px-20"
          bgColor="bg-error"
          onClick={handleSubmit}
        >
          Save changes
        </Button>
      </div>
    </>
  );
}

export default CreateTag;
