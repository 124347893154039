import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useMutation } from 'react-query';

import Button from '../../components/inputs/Button';
import ViewWrapper from '../../wrappers/ViewWrapper';
import TextInput from '../../components/inputs/Text';
import useGetQuery from '../../hooks/useGetQuery';
import SelectInput from '../../components/inputs/Select';
import notification from '../../utilities/notification';
import Loading from '../../components/commons/Loading';
import handleFetch from '../../services/api/handleFetch';

function AddBankAccount() {
  const [form, setForm] = useState(null);
  const [acctName, setAcctName] = useState('');
  const apiFlag = useRef('');

  const { data, status, error } = useGetQuery({ endpoint: 'banks', queryKey: ['banks'] });

  useEffect(() => {
    if (status === 'error') {
      notification({
        title: 'Error',
        message: error?.toString() || 'Something went wrong while fetching data',
        type: 'danger'
      });
    }
  }, [status]);

  const validateForm = () => {
    if (!form?.bank?.value) return 'Select bank name.';
    if (!form?.accountNumber) return 'Enter your account number.';
    if (form?.accountNumber?.length < 9) return 'Enter a valid account number.';
    return null;
  };

  const addBankMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      if (apiFlag.current === 'resolving') {
        setAcctName(res?.data?.account_name || '');
      } else {
        notification({
          title: 'Success',
          message: 'You have successfully added new bank details',
          type: 'success'
        });
        setForm(null);
        setAcctName('');
      }
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleResolveAcct = (acctNumber) => {
    const errorMsg = validateForm();

    if (errorMsg) {
      notification({
        title: 'Form Error',
        message: errorMsg,
        type: 'danger'
      });
      return;
    }
    apiFlag.current = 'resolving';

    const pQuery = {
      account_number: acctNumber,
      bank_id: form?.bank?.value
    };

    addBankMutation.mutate({
      endpoint: 'wallet', extra: '/bank-accounts/resolve-account-number', method: 'GET', pQuery, auth: true
    });
  };

  const handleChange = (val, type = 'input', iName = '') => {
    if (type === 'input') {
      const { value, name } = val.target;
      setForm((state) => ({ ...state, [name]: value }));

      if (name === 'accountNumber' && value?.length === 10) {
        handleResolveAcct(value);
      }
    } else {
      setForm((state) => ({ ...state, [iName]: val }));
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const errorMsg = validateForm();

    if (errorMsg) {
      notification({
        title: 'Form Error',
        message: errorMsg,
        type: 'danger'
      });
      return;
    }
    apiFlag.current = 'adding';

    const body = {
      account_number: form?.accountNumber,
      bank_id: form?.bank?.value
    };

    addBankMutation.mutate({
      endpoint: 'wallet', extra: '/bank-accounts', method: 'POST', body, auth: true
    });
  };

  const { isLoading } = addBankMutation;

  return (
    <ViewWrapper header="Add bank account">
      {(status === 'loading' || isLoading) && <Loading />}

      <div className="mb-8 sm:mb-16">
        <Link to="/wallet/banks" className="inline-flex items-center hover:underline">
          <MdOutlineArrowBack className="mr-2" />
          Back
        </Link>
        <h2 className="font-bold  text-2xl sm:text-3xl">Add bank account</h2>
      </div>

      <div className="w-full mb-20">
        <h4 className="text-base font-semibold mb-5 sm:mb-10">Connect a bank account to your monieworx account</h4>

        <div className="w-full max-w-lg">
          <div className="w-full border border-primary rounded-2xl p-7 mb-7">
            <div className="mb-8">
              <SelectInput
                value={form?.bank}
                label="Bank name"
                placeholder="Choose a bank"
                disabled={status !== 'success'}
                onChange={(val) => handleChange(val, 'select', 'bank')}
                options={data?.data?.map((item) => ({ label: item?.name, value: item?.id })) || []}
              />
            </div>

            <div className="mb-8">
              <TextInput
                name="accountNumber"
                value={form?.accountNumber || ''}
                label="Account Number"
                onChange={(e) => /^\d{0,10}$/g.test(e.target.value) && handleChange(e)}
                placeholder="Enter bank account number"
              />
            </div>

            <div className="mb-8">
              <TextInput
                value={acctName}
                label="Account Name"
                readOnly
                placeholder="Show account name"
              />
            </div>

            <div className="">
              <Button bgColor="bg-error" className="min-w-max" onClick={handleSubmit}>
                Add Bank Account
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ViewWrapper>
  );
}

export default AddBankAccount;
