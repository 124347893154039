import React, { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function TextInput({
  className, name, label, type, value, onChange, readOnly, disabled, error,
  onBlur, onKeyDown, minValue, maxValue, maxLength, placeholder, inputBg = 'bg-white'
}) {
  const [inputType, setInputType] = useState(type || 'text');

  return (
    <div className={`${className || ''} relative`}>
      <label htmlFor={name} className={`text-xs absolute ${inputBg} text-primary px-2 -top-2 left-4`}>
        {label}
      </label>
      <input
        type={inputType}
        name={name}
        id={name}
        value={value}
        className={`appearance-none border rounded w-full pt-5 pb-4 px-3 ${inputBg
        } text-gray-700 leading-tight focus:outline-none border-primary`}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabled}
        onKeyDown={onKeyDown}
        min={minValue}
        max={maxValue}
        maxLength={maxLength}
        autoComplete="off"
        placeholder={placeholder || ''}
      />
      {error ? <p className="text-error text-sm">{error}</p> : null}

      {type === 'password' && (
        <span className="absolute right-3 bottom-3.5 text-primary">
          {inputType === 'password' ? (
            <AiFillEye className="w-6 h-auto cursor-pointer" onClick={() => setInputType('text')} />
          ) : (
            <AiFillEyeInvisible className="w-6 h-auto cursor-pointer" onClick={() => setInputType('password')} />
          )}
        </span>
      )}
    </div>
  );
}

export default TextInput;
