import React, { useState } from 'react';

import NoticeIcon from '../../../assets/svgs/notification.svg';

function NotificationItem({ data }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="w-full my-3 px-3 py-2 rounded-xl bg-[#FEF4E9]">
      <div
        role="presentation"
        onClick={() => setShowDetails(!showDetails)}
        className="w-full flex items-center space-x-3 cursor-pointer"
      >
        <div className="flex items-center space-x-1">
          <img src={NoticeIcon} alt="" className="" />
          <span className={`line-clamp-1 ${showDetails ? 'font-bold' : ''}`}>{data?.body}</span>
        </div>
        <div className="min-w-max">
          {data.created_at}
        </div>
      </div>
      {showDetails && <p className="w-full text-sm pl-7 py-2 mt-3 border-t">{data?.body}</p>}
    </div>
  );
}

export default NotificationItem;
