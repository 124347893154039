import React from 'react';
import PropTypes from 'prop-types';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

function PhoneNoInput({
  value, onChange, disabled, label, name, className, inputBg = 'bg-white'
}) {
  return (
    <div className={`${className} relative`}>
      <ReactPhoneInput
        name={name}
        country="ng"
        value={value}
        specialLabel={label}
        inputClass={`!w-full h-[55.5px] !pt-4 !pb-4 !rounded !text-sm ${inputBg
        } !text-gray-700 leading-tight focus:!shadow-none focus:!border !border-primary`}
        containerClass="phone-input"
        buttonClass="!rounded-l-md"
        autoFormat={false}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

PhoneNoInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string
};

PhoneNoInput.defaultProps = {
  value: '',
  onChange: () => { },
  disabled: false,
  label: '',
  name: '',
  className: ''
};

export default PhoneNoInput;
