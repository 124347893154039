import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';

import TextInput from '../../../../components/inputs/Text';
import Button from '../../../../components/inputs/Button';
import handleFetch from '../../../../services/api/handleFetch';
import notification from '../../../../utilities/notification';
import Loading from '../../../../components/commons/Loading';

function ValidateEmail({ onNext = () => {} }) {
  const [, setCookie] = useCookies(['data']);
  const [email, setEmail] = useState(null);

  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      setCookie('data', { email }, { secure: true, sameSite: true });
      onNext();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateForm = () => {
    if (!email) return 'Email address is required';
    if (!/^([a-zA-Z0-9_\-.&]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)) {
      return 'Please, enter a valid email';
    }
    return null;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const error = validateForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    submitMutation.mutate({
      endpoint: 'forgotPassword', extra: '/send-otp', method: 'POST', body: { email }
    });
  };

  const { isLoading } = submitMutation;

  return (
    <>
      {isLoading && <Loading />}

      <h3 className="font-semibold text-xl">Forgot password?</h3>

      <div className="flex flex-wrap justify-between mt-5 mb-5 sm:mb-10">
        <p className="text-error">Don&apos;t worry! We can get you back in. Please enter your registered email address.</p>
      </div>

      <div className="w-full">
        <div className="w-full mb-5 sm:mb-10">
          <div className="w-full md:w-1/2">
            <TextInput
              label="Email Address"
              value={email || ''}
              name="email"
              type="email"
              placeholder="example@mail.com"
              onChange={(e) => setEmail(e?.target?.value)}
            />
          </div>
        </div>

        <p className="">
          <span className="text-primary">Have an account?&nbsp;</span>
          <Link className="text-error font-bold hover:underline" to="/">Login</Link>
        </p>

        <Button className="rounded-full mt-10" paddingY="py-4" paddingX="px-20" bgColor="bg-error" onClick={handleSubmit}>Submit</Button>
      </div>
    </>
  );
}

export default ValidateEmail;
