import React from 'react';

// import { CgChevronRightO } from 'react-icons/cg';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/commons/Loading';
import Button from '../../components/inputs/Button';

// import PeriodSelect from '../../components/inputs/PeriodSelect';
import useGetQuery from '../../hooks/useGetQuery';
import ViewWrapper from '../../wrappers/ViewWrapper';
import Deals from './components/Deals';
import PerformanceCards from './components/PerformanceCards';

function index() {
  const navigate = useNavigate();
  const { data, status, error } = useGetQuery({ endpoint: 'portfolio', queryKey: 'portfolio' });

  return (
    <ViewWrapper header="Portfolio">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Portfolio</h2>
      </div>

      <div className="w-full max-w-5xl">
        <div className="w-full mb-16">
          {/* <div className="flex flex-wrap space-y-2 justify-between items-center mb-2 sm:mb-5">
            <div className="flex flex-wrap space-y-2 items-center">
              <h4 className="text-base font-semibold mr-4">Your investment portfolio:</h4>
              <PeriodSelect />
            </div>
            <button type="button" className="inline-flex items-center text-error font-semibold hover:underline">
              View Statement
              <CgChevronRightO className="w-6 h-auto ml-2" />
            </button>
          </div> */}

          <PerformanceCards data={data?.data} />
        </div>

        <div className="w-full">
          <div className="flex flex-wrap space-y-2 justify-between items-center mb-2 sm:mb-5">
            <h3 className="font-bold text-2xl sm:text-3xl">My investments</h3>
            {/* <button type="button" className="inline-flex items-center text-primary font-semibold hover:underline">
              View Goals
              <CgChevronRightO className="w-6 h-auto ml-2" />
            </button> */}
          </div>
          <p className="text-[#5F7C51] text-base mb-5">
            Here are the deals with the MSMEs you are backing on monieworx.
          </p>

          <div className="w-full xl:w-2/3">
            <Deals data={data?.data?.investments} />
          </div>
        </div>
      </div>

      <Modal open={status === 'error'} center showCloseIcon={false}>
        <div className="py-5">
          <p className="mb-5">
            {error?.toString()}
          </p>
          <div className="flex justify-center space-x-3">
            <Button onClick={() => navigate(-1)}>Back</Button>
            <Button onClick={() => window.location.reload()}>Refresh page</Button>
          </div>
        </div>
      </Modal>
    </ViewWrapper>
  );
}

export default index;
