import React from 'react';

import ViewWrapper from '../../wrappers/ViewWrapper';
import useGetQuery from '../../hooks/useGetQuery';
import Loading from '../../components/commons/Loading';
import CreateTag from './components/CreateTag';

function Community() {
  const { data, status, error } = useGetQuery({ endpoint: 'user', queryKey: ['community'] });

  return (
    <ViewWrapper header="Community">
      {(status === 'loading') && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Community</h2>
      </div>

      {status === 'success' && (
        <div className="w-full max-w-3xl">
          {data?.data?.user?.monie_tag
            ? (
              <p className="text-xl text-primary">
                monieworx tag created @
                {data?.data?.user?.monie_tag}
                . Watch out for this page.
              </p>
            )
            : <CreateTag />}
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default Community;
