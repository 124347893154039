import React from 'react';
import { useNavigate } from 'react-router-dom';

import IdCard from '../../assets/svgs/id-card.svg';
import ScanFace from '../../assets/svgs/scan-face.svg';

import ViewWrapper from '../../wrappers/ViewWrapper';
import useGetQuery from '../../hooks/useGetQuery';
import Loading from '../../components/commons/Loading';
import Button from '../../components/inputs/Button';

function Identity() {
  const navigate = useNavigate();
  const { data, status, error } = useGetQuery({ endpoint: 'user', queryKey: ['user'] });

  return (
    <ViewWrapper header="Identity">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Identity</h2>
      </div>

      {status === 'success' && (
        <div className="w-full max-w-4xl">
          <h4 className="text-base font-semibold mb-5">Your linked identity on monieworx:</h4>

          <div className="flex flex-wrap -mx-4">
            <div className="w-full max-w-md md:w-1/2 px-4 my-4">
              <div className="w-full h-full border border-[#FFE7CC] rounded-2xl p-5">
                <div className="flex mt-5">
                  <div className="">
                    <img src={IdCard} alt="" className="w-20 mr-5" />
                  </div>
                  <div className="w-[calc(100%-100px)]">
                    <h3 className="text-base font-semibold">Govt ID</h3>
                    <hr className="my-2" />
                    <p className="">Connect your verified government issued  identity to your monieworx profile.</p>
                  </div>
                </div>
                <div className="flex justify-end mt-5">
                  <Button
                    className="rounded-full"
                    paddingY="py-2"
                    onClick={() => navigate('/wallet/identities/verify-ids')}
                    bgColor={data?.data?.user?.verification_tier > 0 ? 'bg-primary' : 'bg-error'}
                  >
                    {data?.data?.user?.verification_tier > 0 ? 'Linked' : 'Yet to link'}
                  </Button>
                </div>
              </div>
            </div>

            <div className="w-full max-w-md md:w-1/2 px-4 my-4">
              <div className="w-full h-full border border-[#FFE7CC] rounded-2xl p-5">
                <div className="flex mt-5">
                  <div className="">
                    <img src={ScanFace} alt="" className="w-20 mr-5" />
                  </div>
                  <div className="w-[calc(100%-100px)]">
                    <h3 className="text-base font-semibold">Facial Verification</h3>
                    <hr className="my-2" />
                    <p className="">Connect your verified facial  identity to your monieworx profile.</p>
                  </div>
                </div>
                <div className="flex justify-end mt-5">
                  <Button
                    className="rounded-full"
                    paddingY="py-2"
                    disabled={data?.data?.user?.verification_tier > 1}
                    bgColor={data?.data?.user?.verification_tier > 1 ? 'bg-primary' : 'bg-error'}
                  >
                    {data?.data?.user?.verification_tier > 1 ? 'Linked' : 'Yet to link'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default Identity;
