import React from 'react';
import { Link } from 'react-router-dom';

import walletShortcuts from '../../../data/walletShortcuts';

function Shortcuts() {
  return (
    <div className="w-full">
      <div className="flex flex-wrap -mx-4 -my-2.5">
        {walletShortcuts?.map((item) => (
          <div className="w-full sm:w-1/2 mdx2:w-1/3 px-4 py-2.5" key={item.title}>
            <Link to={item?.link} className="block h-full">
              <div className="bg-[#FFE7CC] transition-all hover:bg-opacity-70 w-full h-full px-8 py-5 rounded-3xl">
                <div className="relative">
                  <img src={item?.icon} className="w-8 h-auto absolute right-0 top-0" alt={item?.deal} />
                  <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-5">{item?.title}</h3>
                  <p className="text-base">{item?.desc}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Shortcuts;
