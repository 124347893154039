import React from 'react';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import { ImSad2 } from 'react-icons/im';

import Button from '../inputs/Button';

function VerificationError({ onClose }) {
  const navigate = useNavigate();

  return (
    <Modal open onClose={onClose} center>
      <div className="py-5 text-center">
        <ImSad2 className="w-16 h-auto mx-auto mb-2 text-error" />
        <h3 className="font-bold text-lg mb-3">Verification Error</h3>
        <p>Please, verify your identity to proceed</p>

        <div className="flex justify-center mt-6">
          <Button
            className="rounded-full"
            onClick={() => navigate('/wallet/identities/verify-ids')}
          >
            Verify
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default VerificationError;
