import React from 'react';
import Loading from '../../components/commons/Loading';
import useGetQuery from '../../hooks/useGetQuery';

import ViewWrapper from '../../wrappers/ViewWrapper';
import CertificateList from './components/CertificateList';

function Certificates() {
  const { data, status, error } = useGetQuery({ endpoint: 'wallet', queryKey: ['wallet'] });

  return (
    <ViewWrapper header="Certificates">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Certificates</h2>
      </div>

      {status === 'success' && (
        <div className="w-full max-w-7xl">
          <h4 className="text-base font-semibold mb-5 sm:mb-10">Your investment certificates on monieworx:</h4>
          <CertificateList data={data?.data?.certificates} />
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default Certificates;
