import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';

import SecurityTab from './SecurityTab';
import AboutTab from './AboutTab';
import PersonalTab from './PersonalTab';

const links = [
  { title: 'Personal', slug: 'personal' },
  { title: 'Security', slug: 'security' },
  { title: 'About monieworx', slug: 'about' }
];

function TabsLayout({ data }) {
  const navigate = useNavigate();
  const location = useLocation();

  const query = qs.parse(location?.search);

  useEffect(() => {
    if (!query.tab) {
      navigate(`/profile?tab=${links[0]?.slug}`);
    }
  }, []);

  return (
    <div className="">
      <ul className="w-full flex bg-gray-300 space-x-7 rounded-md mb-3 overflow-auto slide-wrapper">
        {links.map((item) => (
          <li className="min-w-max" key={item?.slug}>
            <button
              type="button"
              onClick={() => navigate(`/profile?tab=${item?.slug}`)}
              className={`flex relative text-base py-3 px-2 ${query?.tab === item?.slug ? 'before:absolute' : 'before:hidden'
              } before:left-0 before:bottom-0 before:w-full before:h-1.5 before:bg-[#FAA74A]`}
            >
              {item?.title}
            </button>
          </li>
        ))}
      </ul>

      <div className="w-full min-h-[500px] bg-[#FFFBFB] border rounded-xl p-5">
        {query?.tab === 'personal' && <PersonalTab data={data?.user} />}
        {query?.tab === 'security' && <SecurityTab data={data?.user} />}
        {query?.tab === 'about' && <AboutTab data={data?.user} />}
      </div>
    </div>
  );
}

export default TabsLayout;
