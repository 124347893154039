import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { useMutation } from 'react-query';
import { BiMoney } from 'react-icons/bi';

import Button from '../../../components/inputs/Button';
import Loading from '../../../components/commons/Loading';
import notification from '../../../utilities/notification';
import handleFetch from '../../../services/api/handleFetch';

const payoutMethods = [
  { label: 'Cash', value: 'cash' },
  { label: 'Token', value: 'token' }
];

function SetPayoutMethod({ data, onClose = () => {} }) {
  const [selectedBank, setSelectedBank] = useState('cash');

  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      notification({
        title: 'Success',
        message: `You have successfully set the payout method for your maturing investment to "${selectedBank}"`,
        type: 'success'
      });
      onClose();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleSubmit = () => {
    const body = {
      payout_method: selectedBank
    };

    submitMutation.mutate({
      endpoint: 'portfolio', extra: `/${data?.id}/set-payout-method`, method: 'POST', body
    });
  };

  const { isLoading } = submitMutation;

  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-lg' }}>
      {isLoading && <Loading />}

      <div className="w-full p-5">
        <h2 className="font-bold text-2xl mb-5">Set Payout Method</h2>
        <div className="mb-5">
          <p className="text-primary text-xl font-semibold mb-1">Set payouy method for your maturing investment</p>
        </div>

        <div className="w-full mb-5 sm:mb-10">
          <p className="font-semibold mb-2">Select payout method:</p>
          {payoutMethods?.map((item) => (
            <div className="w-full flex justify-between items-center mb-5" key={item?.value}>
              <div className="flex items-center space-x-3">
                <span className="w-10 h-10 rounded-[18px] bg-[#232323] p-2">
                  <BiMoney className="text-white w-6 h-auto" />
                </span>
                <div>
                  <h2 className="font-bold text-base">{item?.label}</h2>
                </div>
              </div>

              <input
                type="radio"
                className="w-6 h-6 cursor-pointer"
                name="selected"
                value={item?.value}
                onChange={(e) => setSelectedBank(e?.target?.value)}
                checked={selectedBank === item?.value}
              />
            </div>
          ))}
        </div>

        <Button
          className="rounded-full mt-10"
          paddingY="py-4"
          paddingX="px-20"
          bgColor="bg-error"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default SetPayoutMethod;
