import React, { Fragment } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Menu, Transition } from '@headlessui/react';
import { MdExpandMore } from 'react-icons/md';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { useCookies } from 'react-cookie';

import Avatar from '../../assets/images/avatar.png';
import { logout } from '../../utilities/auth';
import SearchInput from '../inputs/Search';
import Notifications from './Notifications';

function TopBar() {
  const [cookies] = useCookies(['data']);
  const { user } = cookies?.data || {};

  const ppic = localStorage.getItem('ppic');

  return (
    <div className="fixed left-0 w-full lg:pl-72 bg-white border-b z-10">
      <div className="w-full max-w-screen-2xl flex justify-end sm:justify-between
        items-center h-20 pr-5 sm:pr-10 pl-20 lg:pl-10"
      >
        <div className="w-full max-w-xs hidden sm:block">
          <SearchInput className="w-full" />
        </div>

        <div className="flex items-center text-primary-gray-2 ml-2">
          <Notifications />

          <div className="w-10 h-10 rounded-full overflow-hidden">
            <img src={ppic || user?.profile_pic || Avatar} className="w-full h-full" alt="Avatar" />
          </div>

          <div className="ml-1">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center p-2 text-sm font-medium
                  rounded-md focus:outline-none hover:bg-gray-50"
                >
                  <div className="text-left text-[#5F7C51]">
                    <p className="font-semibold text-base">{user?.f_name || <Skeleton />}</p>
                    {user?.monie_tag ? (
                      <p className="text-xs">
                        @
                        {user?.monie_tag}
                      </p>
                    ) : (
                      <p className="text-xs">{user?.email || <Skeleton />}</p>
                    )}
                  </div>
                  <MdExpandMore
                    className="w-6 h-auto ml-2 -mr-1 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-40 mt-2 origin-top-right
                  bg-white divide-y divide-gray-100 rounded-md shadow-lg
                  ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => logout()}
                          className={`${
                            active ? 'bg-error text-white' : 'text-error'
                          } group flex rounded-md items-center w-full px-2 py-1.5 my-1 text-sm`}
                        >
                          <RiLogoutCircleLine
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
