import React from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import ViewWrapper from '../../wrappers/ViewWrapper';
import Button from '../../components/inputs/Button';
import { formatCurrency } from '../../utilities/general';
import useGetQuery from '../../hooks/useGetQuery';
import Loading from '../../components/commons/Loading';

function InvestmentDetails() {
  const navigate = useNavigate();
  const params = useParams();

  const { data, status, error } = useGetQuery({
    endpoint: 'portfolio', param: params?.id, queryKey: ['investments', params?.id]
  });

  return (
    <ViewWrapper header="Investment details">
      {status === 'loading' && <Loading />}

      <div className="mb-5 sm:mb-10">
        <button
          type="button"
          className="inline-flex items-center hover:underline"
          onClick={() => navigate('/portfolio')}
        >
          <MdOutlineArrowBack className="mr-2" />
          Back
        </button>
        <h2 className="font-bold  text-2xl sm:text-3xl">Investment details</h2>
      </div>

      {status === 'success' && (
        <div className="w-full max-w-2xl">
          <div className="w-full mb-5 sm:mb-10">
            <p className="font-semibold mb-5">You have invested in:</p>

            <div className="flex justify-between items-center">
              <div className="flex mb-5 items-center space-x-4">
                <div>
                  <img src={data?.data?.offer?.issuer_logo} className="w-16 h-16 rounded-full bg-gray-200" alt="deal company logo" />
                </div>
                <div className="w-[calc(100%-80px)]">
                  <h2 className="font-bold text-xl">
                    {data?.data?.offer?.title}
                          &nbsp;-&nbsp;
                    {data?.data?.offer?.issuer}
                  </h2>
                  <p className="font-bold text-base text-error">
                    {`Earn ${data?.data?.offer?.interest_rate ?? 0
                    }% interest in ${data?.data?.offer?.tenure ?? 0} days`}
                  </p>
                </div>
              </div>

              {data?.data?.fulfilled_at && <p className="bg-[#FFE7CC] px-6 py-2 rounded-full">Alloted</p>}
            </div>
          </div>

          <div className="w-full">
            <div className="w-full max-w-3xl">
              <div className="w-full mb-5 sm:mb-10">
                <h3 className="text-base font-bold mb-5">{`About ${data?.data?.offer?.issuer}`}</h3>
                <p className="mb-5">
                  {data?.data?.offer?.about_issuer}
                </p>
              </div>

              <div className="flex space-x-14 mb-5 sm:mb-10">
                <div className="">
                  <h2 className="font-bold text-sm text-primary">Amount invested</h2>
                  <p className="font-bold text-base">{formatCurrency(data?.data?.invested_amount)}</p>
                </div>
                <div className="">
                  <h2 className="font-bold text-sm text-primary">% of deal bought</h2>
                  <p className="font-bold text-base">{`${data?.data?.percent_bought}%`}</p>
                </div>
              </div>
              <div className="flex space-x-14 mb-5 sm:mb-10">
                <div className="">
                  <h2 className="font-bold text-sm text-primary">Expected Income</h2>
                  <p className="font-bold text-base">{formatCurrency(data?.data?.total_interest_payable)}</p>
                </div>
                <div className="">
                  <h2 className="font-bold text-sm text-primary">Duration</h2>
                  <p className="font-bold text-base">{`${data?.data?.offer?.days_to_go || 0} days`}</p>
                </div>
              </div>
              {data?.data?.fulfilled_at && (
                <div className="flex space-x-14 mb-5 sm:mb-10">
                  <div className="">
                    <h2 className="font-bold text-sm text-primary">Payments Schedule</h2>
                    <p className="font-bold text-base">{`${data?.data?.offer?.payback_days_count || 0} payments`}</p>
                  </div>
                </div>
              )}

              <div className="">
                {data?.data?.receipt_url && (
                  <Button
                    onClick={() => window.open(data?.data?.receipt_url, '_blank')}
                  >
                    Download Receipt
                  </Button>
                )}
              </div>
              {!data?.data?.certificate_url && (
                <p className="ext-xs mt-3">Your investment certificate will be issued to you when the round closes.</p>
              )}
            </div>
          </div>
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default InvestmentDetails;
