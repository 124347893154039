import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';
import qs from 'query-string';

import Logo from '../../../../assets/svgs/logo.svg';
import Bg from '../../../../assets/images/auth-bg.png';

import TextInput from '../../../../components/inputs/Text';
import Button from '../../../../components/inputs/Button';
import handleFetch from '../../../../services/api/handleFetch';
import notification from '../../../../utilities/notification';
import Loading from '../../../../components/commons/Loading';
import AuthFooter from '../../../../components/commons/AuthFooter';

function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setCookie] = useCookies(['data']);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const fooRef = useRef(false);

  const query = qs.parse(location?.search);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'monieworx - Login';
    if (localStorage.getItem('err')) {
      notification({
        title: 'Error',
        message: localStorage.getItem('err'),
        type: 'danger'
      });
      localStorage.clear();
    }
  }, []);

  const loginMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      localStorage.setItem('d', JSON.stringify(res?.data));
      const { user, token } = res?.data || {};
      delete user?.investment_goal;
      delete user?.investments;
      delete user?.active_financed_orders;

      const data = { user, token };

      setCookie('data', data, { secure: true, sameSite: true });
      setTimeout(() => {
        notification({
          message: `Welcome back ${data?.user?.f_name}`,
          type: 'success'
        });
        navigate(query?._p || '/home');
      }, 10);
    },
    onError: (err) => {
      loginMutation.reset();
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleLogin = (e) => {
    e?.preventDefault();

    if (query._em && query._lt) {
      fooRef.current = true;
      loginMutation.mutate({
        endpoint: 'login-link', method: 'POST', body: { email: query._em, token: query._lt }, auth: false
      });
      navigate(location.pathname);
    } else if (username && password) {
      loginMutation.mutate({
        endpoint: 'login', method: 'POST', body: { username, password }, auth: false
      });
    } else if (e?.type === 'click') {
      notification({
        title: 'Form Error',
        message: 'Please, enter a valid email and password',
        type: 'danger'
      });
    }
  };

  useEffect(() => {
    if (query._lt && query._em && fooRef.current === false) {
      handleLogin();
    }
  }, [query._lt]);

  const { isLoading, isSuccess } = loginMutation;

  const handleKeyDown = (e) => {
    if (!isLoading && e.which === 13) {
      handleLogin();
    }
  };

  return (
    <>
      {(isLoading || isSuccess) && <Loading />}

      <div style={{ background: `no-repeat url(${Bg})` }} className="relative w-full h-full min-h-screen !bg-cover px-5 pt-40 pb-5">
        <div className="w-full max-w-xl m-auto py-12 px-5 sm:px-20 mb-24 bg-white rounded-b-3xl border border-primary">
          <div className="">
            <img
              alt="Logo"
              src={Logo}
              className="w-48 sm:w-64 h-auto mb-3 sm:mb-10 mx-auto"
            />

            <h3 className="text-center font-semibold text-xl sm:text-2xl">Welcome back!</h3>

            <div className="flex justify-end mt-5 sm:mt-10 mb-5">
              <p className="">
                <span className="text-primary">New User?&nbsp;</span>
                <Link className="text-error font-bold hover:underline" to="/sign-up">Sign up</Link>
              </p>
            </div>

            <div className="w-full mt-7">
              <TextInput
                label="Email/Phone"
                value={username || ''}
                onChange={(e) => setUsername(e?.target?.value)}
                onKeyDown={handleKeyDown}
                className="w-full mb-5 sm:mb-10"
              />
              <TextInput
                label="Password"
                value={password || ''}
                onChange={(e) => setPassword(e?.target?.value)}
                type="password"
                onKeyDown={handleKeyDown}
                className="w-full"
              />
              <div className="flex justify-end mt-2 mb-5 sm:mb-10">
                <Link className="text-error hover:underline" to="/forget-password">Forget Password?</Link>
              </div>

              <Button className="rounded-full mx-auto" paddingY="py-4" paddingX="px-20" onClick={handleLogin}>Login</Button>
            </div>
          </div>
        </div>

        <AuthFooter />
      </div>
    </>
  );
}

export default LoginForm;
