import React from 'react';
import Modal from 'react-responsive-modal';
import { ImSad2 } from 'react-icons/im';

import Button from '../../../components/inputs/Button';

function NoCard({ onClose, onAddCard }) {
  return (
    <Modal open onClose={onClose} center>
      <div className="py-5 text-center">
        <ImSad2 className="w-16 h-auto mx-auto mb-2 text-error" />
        <h3 className="font-bold text-lg mb-3">No Card</h3>
        <p>You currently have not added any card for transactions</p>

        <div className="flex justify-center mt-6">
          <Button
            className="rounded-full"
            onClick={onAddCard}
          >
            Add Card
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default NoCard;
