import React from 'react';
import { useMutation } from 'react-query';

import PdfIcon from '../../../assets/svgs/pdf-icon.svg';

import NoData from '../../../components/commons/NoData';
import Button from '../../../components/inputs/Button';

import handleFetch from '../../../services/api/handleFetch';
import notification from '../../../utilities/notification';
import Loading from '../../../components/commons/Loading';

function CertificateItem({ data }) {
  const mailCertMutation = useMutation(handleFetch, {
    onSuccess: () => {
      notification({
        title: 'Success',
        message: `A copy of your certificate for ${data?.title} has been sent to your email`,
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const mailCertificate = () => {
    mailCertMutation.mutate({
      endpoint: 'portfolio', extra: `/${data.id}/mail-certificate`, method: 'POST', auth: true
    });
  };

  const { isLoading } = mailCertMutation;

  return (
    <div className="w-full">
      {isLoading && <Loading />}

      <div className="w-full px-5 py-10 rounded-2xl border border-[#FFE7CC] bg-primary/20 relative">
        <img src={PdfIcon} alt="" className="absolute right-5 top-5 w-9 h-9" />

        <div className="mb-5">
          <h4 className="text-base font-bold w-[calc(100%-45px)]">{data?.title}</h4>
        </div>

        <div className="text-center">
          <p className="inline-block bg-white/70 rounded-2xl p-3 font-bold text-base text-primary">
            invested sum:&nbsp;
            {data?.invested_amount_formatted}
          </p>
        </div>
      </div>

      {data?.url && (
        <div className="flex justify-center space-x-5 mt-3">
          <Button
            border
            paddingY="py-2"
            onClick={() => window.open(data?.url, '_blank')}
            borderColor="border-primary"
            bgColor="bg-white"
            textColor="text-primary"
          >
            Download
          </Button>
          <Button paddingY="py-2" bgColor="bg-error" onClick={mailCertificate}>email</Button>
        </div>
      )}
    </div>
  );
}

function CertificateList({ data = [] }) {
  return (
    <div className="w-full">
      <div className="flex flex-wrap -m-4">
        {data?.map((item) => (
          <div className="w-full sm:w-1/2 xl:w-1/3 2xl:w-1/4 p-4" key={item.id}>
            <CertificateItem data={item} />
          </div>
        ))}

        {data?.length < 1 && (
          <NoData message="You currently do not have any certificate" />
        )}
      </div>
    </div>
  );
}

export default CertificateList;
