import React from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/commons/Loading';

import Button from '../../components/inputs/Button';
import useGetQuery from '../../hooks/useGetQuery';
import ViewWrapper from '../../wrappers/ViewWrapper';
import BankList from './components/BankList';

function Banks() {
  const navigate = useNavigate();

  const {
    data, status, error, isFetching
  } = useGetQuery({ endpoint: 'wallet', extra: '/bank-accounts', queryKey: ['bank-accounts'] });

  return (
    <ViewWrapper header="Banks">
      {(status === 'loading' || isFetching) && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Banks</h2>
      </div>

      {status === 'success' && (
        <div className="w-full">
          <h4 className="text-base font-semibold mb-5">Connected bank accounts:</h4>

          <div className="w-full max-w-3xl flex flex-wrap-reverse">
            <BankList data={data?.data} />

            <div className="w-[200px] pl-5 mb-5">
              <Button
                onClick={() => navigate('/wallet/banks/add-bank-account')}
                fontSize="text-base"
                className="w-full min-w-max"
              >
                <BsPlusLg className="mr-3 w-6 h-auto" />
                Add Bank
              </Button>
            </div>
          </div>
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default Banks;
