import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { HiEllipsisVertical } from 'react-icons/hi2';

export default function PopoverOptions({ options = [] }) {
  return (
    <div className="">
      <Menu className="relative">
        {({ open }) => (
          <>
            <Menu.Button className="focus:outline-none">
              <HiEllipsisVertical className={`${open ? '' : 'text-opacity-50'} h-auto w-7 p-1 text-black rounded shadow `} />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items className="absolute right-0 z-10 bg-white border w-32 shadow-md">
                {options?.map((item) => (
                  <Menu.Item key={item?.title}>
                    <button
                      type="button"
                      onClick={item?.action}
                      className="w-full text-left px-2 py-2 hover:bg-gray-50"
                    >
                      {item?.title}
                    </button>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
