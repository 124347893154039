import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { useMutation } from 'react-query';

import Keys from '../../../assets/images/keys.png';
import Button from '../../../components/inputs/Button';
import Loading from '../../../components/commons/Loading';
import notification from '../../../utilities/notification';
import handleFetch from '../../../services/api/handleFetch';
import TextInput from '../../../components/inputs/Text';

function ChangePassword({ onClose = () => {} }) {
  const [form, setForm] = useState({});

  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      notification({
        title: 'Success',
        message: 'You have successfully changed your password',
        type: 'success'
      });
      onClose();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm((state) => ({ ...state, [name]: value }));
  };

  const validateForm = () => {
    if (!form?.password) return 'Current password is required.';
    if (!form?.newPassword) return 'New password is required';
    if (form?.password === form?.newPassword) return 'New password must be different from the new one.';
    if (form?.password?.length < 8) return 'Password must be at least 8 characters long.';
    if (form?.newPassword !== form?.confirmPassword) return 'New password Mismatch.';
    return null;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const err = validateForm();

    if (err) {
      notification({
        title: 'Form Error',
        message: err,
        type: 'danger'
      });
      return;
    }

    const body = {
      password: form?.newPassword,
      password_confirmation: form?.confirmPassword
    };

    submitMutation.mutate({
      endpoint: 'user', extra: '/change-password', method: 'POST', body
    });
  };

  const { isLoading } = submitMutation;

  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-lg' }}>
      {isLoading && <Loading />}

      <div className="w-full p-5">
        <h2 className="font-bold text-2xl mb-5 sm:mb-10">Change password</h2>

        <div className="flex items-center space-x-3 mb-6">
          <span className="">
            <img src={Keys} alt="" className="w-16 h-16" />
          </span>
          <div className="">
            <p className="text-primary text-xl font-semibold mb-2">Looks, like you need new keys</p>
            <p className="text-error">Enter a new password to secure your account </p>
          </div>
        </div>

        <div className="w-full">
          <TextInput
            name="password"
            onChange={handleChange}
            value={form?.password || ''}
            type="password"
            label="Current Password"
            className="mb-5 sm:mb-10"
            placeholder="Minimum 8 characters"
          />
          <TextInput
            name="newPassword"
            onChange={handleChange}
            value={form?.newPassword || ''}
            type="password"
            label="New Password"
            className="mb-5 sm:mb-10"
            placeholder="Minimum 8 characters"
          />
          <TextInput
            name="confirmPassword"
            onChange={handleChange}
            value={form?.confirmPassword || ''}
            type="password"
            label="Confirm Password"
            className="mb-5 sm:mb-10"
            placeholder="Minimum 8 characters"
          />
        </div>

        <Button
          className="rounded-full mt-10"
          paddingY="py-4"
          paddingX="px-20"
          bgColor="bg-error"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default ChangePassword;
