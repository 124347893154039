import React from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useCookies } from 'react-cookie';

import Avatar from '../../../assets/images/avatar.png';

import ViewWrapper from '../../../wrappers/ViewWrapper';
import Button from '../../../components/inputs/Button';
import { formatCurrency } from '../../../utilities/general';

function SubscriptionDetails({ data, onBack }) {
  const [cookie] = useCookies(['data']);

  return (
    <ViewWrapper header="Deal Details">
      <div className="mb-5 sm:mb-10">
        <button type="button" className="inline-flex items-center hover:underline" onClick={onBack}>
          <MdOutlineArrowBack className="mr-2" />
          Back
        </button>
        <h2 className="font-bold  text-2xl sm:text-3xl">Deal subscription details</h2>
      </div>

      <div className="w-full mb-5 sm:mb-10">
        <p className="font-semibold mb-5">You have invested in:</p>
        <div className="flex mb-5 items-center space-x-4">
          <div>
            <img src={data?.offer?.issuer_logo} className="w-16 h-16 rounded-full bg-gray-200" alt="deal company logo" />
          </div>
          <div className="w-[calc(100%-80px)]">
            <h2 className="font-bold text-xl">
              {data?.offer?.title}
                    &nbsp;-&nbsp;
              {data?.offer?.issuer}
            </h2>
            <p className="font-bold text-base text-error">
              {`Earn ${data?.offer?.interest_rate ?? 0}% interest in ${data?.offer?.tenure ?? 0} days`}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="w-full max-w-3xl">
          <div className="w-full mb-5 sm:mb-10">
            <h3 className="text-base font-bold mb-5">{`About ${data?.offer?.issuer}`}</h3>
            <p className="mb-5">
              {data?.offer?.about_issuer}
            </p>
          </div>

          <div className="flex items-center space-x-4 mb-5 sm:mb-10">
            <div>
              <img src={cookie?.data?.user?.profile_pic || Avatar} className="w-14 h-auto rounded-full" alt="investor" />
            </div>
            <div>
              <h2 className="font-bold text-sm text-primary">Investor</h2>
              <p className="font-bold text-base">{`${cookie?.data?.user?.f_name} ${cookie?.data?.user?.l_name}`}</p>
            </div>
          </div>

          <div className="mb-5 sm:mb-10">
            <h2 className="font-bold text-sm text-primary">Investment info</h2>
            <p className="font-bold text-base">{`${data?.offer?.max_amount_formatted} discount notes`}</p>
          </div>

          <div className="flex space-x-14 mb-5 sm:mb-10">
            <div className="">
              <h2 className="font-bold text-sm text-primary">Amount invested</h2>
              <p className="font-bold text-base">{formatCurrency(data?.invested_amount)}</p>
            </div>
            <div className="">
              <h2 className="font-bold text-sm text-primary">% of deal bought</h2>
              <p className="font-bold text-base">{`${data?.percent_bought}%`}</p>
            </div>
          </div>
          <div className="flex space-x-14 mb-5 sm:mb-10">
            <div className="">
              <h2 className="font-bold text-sm text-primary">Expected Income</h2>
              <p className="font-bold text-base">{formatCurrency(data?.total_interest_payable)}</p>
            </div>
            <div className="">
              <h2 className="font-bold text-sm text-primary">Duration</h2>
              <p className="font-bold text-base">{`${data?.offer?.tenure || 0} days`}</p>
            </div>
          </div>

          {data?.paid_at && data?.receipt_url && (
            <Button
              onClick={() => window.open(data?.receipt_url, '_blank')}
            >
              Download Receipt
            </Button>
          )}
          <p className="ext-xs mt-3">Your investment certificate will be issued to you when the round closes.</p>
        </div>
      </div>
    </ViewWrapper>
  );
}

export default SubscriptionDetails;
