import React from 'react';
import { BsArrowDownLeft, BsArrowUpRight } from 'react-icons/bs';

import NoData from '../../../components/commons/NoData';

// import PeriodSelect from '../../../components/inputs/PeriodSelect';

function TransactionList({ data = [] }) {
  return (
    <div className="w-full">
      {/* <div className="flex items-center pb-3 mb-5 justify-between border-b">
        <h4 className="text-base font-semibold mr-4">Transaction History</h4>
        <PeriodSelect />
      </div> */}

      <div className="w-full">
        {data?.map((item) => (
          <div className="w-full mb-7 last-of-type:mb-0" key={item?.id || JSON.stringify(item)}>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-5">
                {item?.type === 'deposit' ? (
                  <BsArrowUpRight className="w-12 smw-16 h-12 sm:h-auto p-4 text-white rounded-full bg-primary" />
                ) : (
                  <BsArrowDownLeft className="w-12 smw-16 h-12 sm:h-auto p-4 rounded-full text-error bg-error/30" />
                )}
                <div>
                  <h3 className="text-base smtext-lg font-bold">{item?.title}</h3>
                  <p className="text-sm sm:text-base">{item?.subtitle}</p>
                </div>
              </div>
              <div className="text-right">
                <h3 className={`text-sm sm:text-base smtext-lg font-bold ${item?.type === 'deposit' ? 'text-primary' : 'text-error'}`}>
                  {item?.invested_amount_formatted}
                </h3>
                <p className="text-sm sm:text-base">{item?.date_formatted}</p>
              </div>
            </div>
          </div>
        ))}

        {data?.length < 1 && (
          <NoData />
        )}
      </div>
    </div>
  );
}

export default TransactionList;
