import React from 'react';

import ViewWrapper from '../../wrappers/ViewWrapper';
import useGetQuery from '../../hooks/useGetQuery';
import Loading from '../../components/commons/Loading';
import TabsLayout from './components/TabsLayout';

function Profile() {
  const { data, status, error } = useGetQuery({ endpoint: 'user', queryKey: ['user-account'], enabled: true });

  return (
    <ViewWrapper header="Profile">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Profile</h2>
      </div>

      {status === 'success' && (
        <div className="w-full max-w-5xl">
          <h4 className="text-base font-semibold mb-7">To enjoy a seamless experience on monieworx, kindly ensure the information you provide below is correct.</h4>

          <TabsLayout data={data?.data} />
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default Profile;
