/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { BsSearch } from 'react-icons/bs';

function SearchInput(props) {
  return (
    <div className={`${props.className || ''} flex items-center bg-white px-3 py-2 rounded-xl border`}>
      <BsSearch className="mr-2 w-5 h-auto" />

      <input
        type="search"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        placeholder={props.placeholder || 'Search'}
        className="text-gray-700 leading-tight focus:outline-none appearance-none w-full"
      />
    </div>
  );
}

export default SearchInput;
