import React from 'react';

import Skeleton from 'react-loading-skeleton';

import PortfolioIcon from '../../../assets/svgs/portfolio-icon.svg';
import IncomeIcon from '../../../assets/svgs/income-icon.svg';
import BalanceIcon from '../../../assets/svgs/balance-icon.svg';

function Card({
  icon, title, amount, description, percentage
}) {
  return (
    <div className="w-full h-full bg-primary/40 rounded-xl px-7 py-5">
      <div className="flex items-center space-x-3 mb-2 sm:mb-4">
        <img src={icon} className="w-8 h-8" alt={title} />
        <h4 className="text-lg font-bold">{title}</h4>
      </div>
      {amount || amount === 0 ? (
        <div className="flex items-center justify-between mb-2 sm:mb-4">
          <h4 className="text-3xl sm:text-4xl font-bold">{amount}</h4>
          <h4 className="text-lg">{percentage}</h4>
        </div>
      ) : (
        <h4 className="text-3xl sm:text-4xl font-bold mb-2 sm:mb-4"><Skeleton baseColor="#5E994224" /></h4>
      )}
      <p className="">{description}</p>
    </div>
  );
}

function PerformanceCards({ data }) {
  return (
    <div className="flex flex-wrap -mx-3">
      <div className="p-3 w-full sm:w-1/2 xl:w-1/3 2xl:w-1/4">
        <Card
          icon={PortfolioIcon}
          title="Portfolio"
          amount={data?.summary?.total_invested_short}
          description="Your total portfolio value"
        />
      </div>
      <div className="p-3 w-full sm:w-1/2 xl:w-1/3 2xl:w-1/4">
        <Card
          icon={BalanceIcon}
          title="Deposit"
          amount={data?.user?.formatted_balance}
          description="Your total amount deposited"
        />
      </div>
      <div className="p-3 w-full sm:w-1/2 xl:w-1/3 2xl:w-1/4">
        <Card
          icon={IncomeIcon}
          title="Earned"
          amount={data?.summary?.total_income_short}
          description="Your paid interest on deposits"
        />
      </div>
    </div>
  );
}
export default PerformanceCards;
