import React, { useState, useEffect } from 'react';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { ImArrowRight2 } from 'react-icons/im';
import moment from 'moment-timezone';

import Button from '../../components/inputs/Button';
import ViewWrapper from '../../wrappers/ViewWrapper';
import TokenTransactions from './components/TokenTransactions';
import TokenBalance from './components/TokenBalance';
import useGetQuery from '../../hooks/useGetQuery';
import Loading from '../../components/commons/Loading';
import SetPayoutMethod from './components/SetPayoutMethod';

function Tokens() {
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [daysLeft, setDaysLeft] = useState(10);

  const { data, status, error } = useGetQuery({ endpoint: 'wallet', queryKey: ['wallet'] });
  const mostRecent = data?.data?.tokens?.tokens?.[0];

  useEffect(() => {
    if (mostRecent?.maturity_date && status === 'success') {
      setDaysLeft(moment(mostRecent?.maturity_date).diff(moment(), 'days'));
    }
  }, [status, data]);

  const handleSetPayout = () => {
    setDaysLeft(10);
    setShowPayoutModal(false);
  };

  return (
    <ViewWrapper header="Tokens">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Tokens</h2>
      </div>

      {status === 'success' && (
        <div className="w-full max-w-3xl">
          <div className="w-full max-w-3xl mb-20">
            <TokenBalance data={data?.data?.tokens} />
          </div>

          {daysLeft > 0 && daysLeft <= 5 && (
            <div className="w-full mb-16">
              <div className="w-full border border-[#FFE7CC] rounded-2xl px-3 py-3">
                <div className="flex justify-between items-center">
                  <div className="flex items-center space-x-5">
                    <span className="bg-[#FFE7CC] rounded-2xl overflow-hidden">
                      <IoIosNotificationsOutline className="w-12 h-auto p-2" />
                    </span>
                    <p className="text-sm sm:text-base w-[calc(100%-68px)]">{`You have an investment maturing in ${daysLeft} days.`}</p>
                  </div>

                  <div className="ml-3">
                    <Button bgColor="bg-error" className="min-w-max" paddingY="py-2" paddingX="px-4" onClick={() => setShowPayoutModal(true)}>
                      Take action
                      <ImArrowRight2 className="ml-3 w-4 h-auto" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="w-full">
            <TokenTransactions data={data?.data?.tokens?.tokens} />
          </div>
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}

      {showPayoutModal && <SetPayoutMethod data={mostRecent} onClose={handleSetPayout} />}
    </ViewWrapper>
  );
}

export default Tokens;
