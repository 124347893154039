import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';

import ChipImg from '../../../assets/svgs/card-chip.svg';
import Loading from '../../../components/commons/Loading';
import cardLogos from '../../../data/cardLogos';
import handleFetch from '../../../services/api/handleFetch';
import notification from '../../../utilities/notification';
import ConfirmPrompt from '../../../components/commons/ConfirmPrompt';
import Button from '../../../components/inputs/Button';

function Card({ data = {} }) {
  const [showSetPrimaryCardPrompt, setShowSetPrimaryCardPrompt] = useState(false);

  const [cookie] = useCookies(['data', 'ac']);
  const { user } = cookie?.data || {};

  const queryClient = useQueryClient();
  const setPrimaryCardMutation = useMutation(handleFetch, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['get-cards']);
      notification({
        title: 'Success',
        message: res?.msg || 'You have successfully set a new primary card',
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleSetPrimaryCard = () => {
    setShowSetPrimaryCardPrompt(false);
    setPrimaryCardMutation.mutate({
      endpoint: 'cards',
      extra: '/mark-as-primary',
      body: { card_id: data?.id },
      method: 'PUT'
    });
  };

  const { isLoading } = setPrimaryCardMutation;

  return (
    <>
      {isLoading && <Loading />}

      <div className="w-[390px] border-2 border-[#FFE7CC] rounded-2xl px-5 py-4" key={data.id}>
        <div className="bg-[url(./assets/svgs/card-bg.svg)] bg-no-repeat bg-cover w-[350px] h-[220px] rounded-3xl px-5 py-4">
          <div className="">
            <h3 className="text-lg font-bold mb-3">{data?.bank}</h3>
            <img src={ChipImg} className="w-12 h-auto mb-2 sm:mb-4" alt="Card Chip" />
            <p className="text-base tracking-widest mb-3">{`**** **** **** ${data?.last_four_digits || '****'}`}</p>
            <div className="flex items-center space-x-2 mb-1">
              <span className="text-[10px]">
                VALID
                <br />
                THRU
              </span>
              <span className="font-semibold">{`${data?.exp_month}/${data?.exp_year}`}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-base font-bold">{data?.acc_name || `${user?.f_name || ''} ${user?.l_name || ''}`}</span>
              <img src={cardLogos[data?.card_type]} className="w-auto h-5 mb-2 sm:mb-4" alt={data?.card_type} />
            </div>
          </div>
        </div>
        {data?.primary ? (
          <p className="text-center text-white font-bold bg-success p-1 mt-2">Primary Card</p>
        ) : (
          <Button
            paddingY="py-1"
            paddingX="px-3"
            className="mt-2"
            bgColor="bg-blue"
            onClick={() => setShowSetPrimaryCardPrompt(true)}
          >
            Set as Primary
          </Button>
        )}
      </div>

      {showSetPrimaryCardPrompt && (
        <ConfirmPrompt
          onClose={() => setShowSetPrimaryCardPrompt(false)}
          title="Cancel Auto Debit"
          message="Are you sure you want to set this card as your primary card?"
          onConfirm={handleSetPrimaryCard}
        />
      )}
    </>
  );
}

export default Card;
