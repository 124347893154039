import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';

import TextInput from '../../../../components/inputs/Text';
import Button from '../../../../components/inputs/Button';
import handleFetch from '../../../../services/api/handleFetch';
import notification from '../../../../utilities/notification';
import Loading from '../../../../components/commons/Loading';
import PasswordResetSuccess from './PasswordResetSuccess';

function SetNewPassword() {
  const [cookie,, removeCookie] = useCookies(['data']);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      removeCookie('data');
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateForm = () => {
    if (!password) return 'Password is required';
    if (password.length < 8) return 'Password must be 8 or more character long';
    if (password !== confirmPassword) return 'Password mismatch';
    return null;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const error = validateForm();

    if (error) {
      notification({
        title: 'Form Error',
        message: error,
        type: 'danger'
      });
      return;
    }
    const { email } = cookie?.data || {};

    submitMutation.mutate({
      endpoint: 'forgotPassword',
      extra: '/update-password',
      method: 'POST',
      body: { email, password, password_confirmation: confirmPassword }
    });
  };

  const { isLoading, isSuccess } = submitMutation;

  return (
    <>
      {isLoading && <Loading />}

      <h3 className="font-semibold text-xl">Create your new password</h3>

      <div className="flex flex-wrap justify-between mt-5 mb-5">
        <p className="text-error">Enter a new password to secure your account. </p>
      </div>

      <div className="w-full">
        <div className="w-full mb-5 sm:mb-10">
          <div className="w-full md:w-1/2">
            <TextInput
              label="Enter Password"
              value={password || ''}
              name="password"
              type="password"
              placeholder="Minimum 8 characters"
              onChange={(e) => setPassword(e?.target?.value)}
            />
          </div>
        </div>

        <div className="w-full mb-5 sm:mb-10">
          <div className="w-full md:w-1/2">
            <TextInput
              label="Confirm Password"
              value={confirmPassword || ''}
              name="confirmPassword"
              type="password"
              placeholder="Minimum 8 characters"
              onChange={(e) => setConfirmPassword(e?.target?.value)}
            />
          </div>
        </div>

        <Button className="rounded-full mt-10" paddingY="py-4" paddingX="px-20" bgColor="bg-error" onClick={handleSubmit}>Submit</Button>
      </div>

      {isSuccess && <PasswordResetSuccess />}
    </>
  );
}

export default SetNewPassword;
