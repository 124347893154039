import React from 'react';

import TokenSymbol from '../../../assets/svgs/token-symbol.svg';

import { formatDate } from '../../../utilities/dateTime';
import { formatCurrency } from '../../../utilities/general';

function TokenTransactions({ data = [] }) {
  return (
    <div className="w-full">
      <div className="w-full">
        {data?.map((item) => (
          <div className="w-full mb-7 last-of-type:mb-0" key={item.id}>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-5">
                <img src={TokenSymbol} className="w-12 sm:w-16 h-12 sm:h-16 rounded-full bg-success p-3" alt="account" />

                <div>
                  <h3 className="text-base sm:text-lg font-bold text-success">
                    {formatCurrency(item?.amount, false)}
                    &nbsp;tokens
                  </h3>
                  <p className="text-sm sm:text-base">{item?.title}</p>
                </div>
              </div>
              <div className="text-right">
                <p className="text-sm sm:text-base">{formatDate(item?.date)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TokenTransactions;
