import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { Interweave } from 'interweave';
import { useCookies } from 'react-cookie';

import Modal from 'react-responsive-modal';
import BtnNotInterested from '../../assets/svgs/not-interested.svg';
import BtnGreatDeal from '../../assets/svgs/great-deal.svg';
import BtnSave from '../../assets/svgs/save-for-later.svg';

import ViewWrapper from '../../wrappers/ViewWrapper';
import Button from '../../components/inputs/Button';
import DealCard from './components/DealCard';
import ConfirmSubscription from './components/ConfirmSubscription';
import SubscriptionDetails from './components/SubscriptionDetails';
import useGetQuery from '../../hooks/useGetQuery';
import Loading from '../../components/commons/Loading';
import { formatCurrency } from '../../utilities/general';
import notification from '../../utilities/notification';
import dateCountDown from '../../utilities/dateCountDown';

function DealDetails() {
  const params = useParams();
  const [cookie,, removeCookie] = useCookies(['ac']);
  const [pageIndex, setPageIndex] = useState(null);
  const [showRisk, setShowRisk] = useState(false);
  const [amount, setAmount] = useState('');
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [countdown, setCountdown] = useState({});

  const { data, status, error } = useGetQuery({
    endpoint: 'offers', param: params?.id, queryKey: ['offers', params?.id]
  });

  useEffect(() => {
    if (cookie?.ac?.status === 'card-added') {
      setAmount(cookie?.ac?.amount);
      setPageIndex(1);
    } else {
      removeCookie('ac');
    }
  }, [cookie?.ac]);

  useEffect(() => {
    let timerId;

    if (status === 'success') {
      const res = dateCountDown(data?.data?.to_be_closed_at);

      if (res?.time === 0) {
        setCountdown(res);
      } else {
        timerId = setInterval(() => {
          setCountdown(dateCountDown(data?.data?.to_be_closed_at));
        }, 1000);
      }
    }

    return () => {
      clearInterval(timerId);
    };
  }, [status, data?.data?.to_be_closed_at]);

  const handleInvest = () => {
    if (!amount) {
      notification({
        title: 'Form Error',
        message: 'Please, enter your investment amount.',
        type: 'danger'
      });
      return;
    }
    setPageIndex(1);
  };

  const handleConfirm = (details) => {
    setAmount('');
    setSubscriptionData(details);
    setPageIndex(2);
  };

  if (pageIndex === 1) {
    return (
      <ConfirmSubscription
        amount={amount}
        data={data?.data}
        onBack={() => setPageIndex(null)}
        onShowSubscription={handleConfirm}
      />
    );
  }

  if (pageIndex === 2) {
    return (
      <SubscriptionDetails
        amount={amount}
        data={subscriptionData}
        onBack={() => setPageIndex(null)}
      />
    );
  }

  return (
    <ViewWrapper header="Deal Details">
      {status === 'loading' && <Loading />}

      <div className="mb-5 sm:mb-10">
        <Link to="/home" className="inline-flex items-center hover:underline">
          <MdOutlineArrowBack className="mr-2" />
          Home
        </Link>
        <h2 className="font-bold  text-2xl sm:text-3xl">Deal Details</h2>
      </div>

      {status === 'success' && (
        <>
          <div className="w-full mb-5 sm:mb-10">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex mb-5 items-center space-x-4">
                <div>
                  <img src={data?.data?.issuer_logo} className="w-16 h-16 rounded-full bg-gray-200" alt="deal company logo" />
                </div>
                <div className="w-[calc(100%-80px)]">
                  <h2 className="font-bold text-xl">
                    {data?.data?.title}
                    &nbsp;-&nbsp;
                    {data?.data?.issuer}
                  </h2>
                  <p className="font-bold text-base text-error">
                    {`Earn ${data?.data?.interest_rate}% interest in ${data?.data?.tenure} days`}
                  </p>
                </div>
              </div>

              <div className="flex flex-wrap mb-5">
                <button type="button" className="flex min-w-max mr-3 mb-3 items-center rounded-full p-1 pr-4 bg-[#FFE7CC]">
                  <img src={BtnNotInterested} alt="" className="w-8 h-auto mr-3" />
                  Not interested
                </button>
                <button type="button" className="flex min-w-max mr-3 mb-3 items-center rounded-full p-1 pr-4 bg-[#FFDEDE]">
                  <img src={BtnGreatDeal} alt="" className="w-8 h-auto mr-3" />
                  great deal!
                </button>
                <button type="button" className="flex min-w-max mr-3 mb-3 items-center rounded-full p-1 pr-4 bg-[#F5F5F5]">
                  <img src={BtnSave} alt="" className="w-8 h-auto mr-3" />
                  save for later
                </button>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-wrap justify-between">
            <div className="w-full mb-20 xl:w-7/12">
              <p className="mb-5 sm:mb-10">{data?.data?.overview}</p>

              <div className="w-full mb-5 sm:mb-10">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 p-4">
                    <div className="w-full bg-[#FFE7CC] px-8 py-6 rounded-2xl">
                      <h3 className="font-bold text-xl mb-3">Funding goal:</h3>
                      <h2 className="font-bold text-3xl mb-3">{data?.data?.max_amount_formatted}</h2>
                      <p className={`text-xs ${countdown?.time === 0 ? 'text-error' : 'text-primary'} font-bold`}>
                        {`${countdown?.days || 0}D:
                          ${countdown?.hours || 0}H:
                          ${countdown?.minutes || 0}M:
                          ${countdown?.seconds || 0}S to go`}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 p-4">
                    <div className="w-full bg-[#E0FFB8] px-8 py-6 rounded-2xl">
                      <h3 className="font-bold text-xl mb-3">Raised:</h3>
                      <h2 className="font-bold text-3xl mb-3">{formatCurrency(data?.data?.invested_amount)}</h2>
                      <p className="text-xs text-error font-bold">{`Raised from ${data?.data?.investors_count} investor(s)`}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full mb-5 sm:mb-10">
                <div className="flex space-x-3 mb-5">
                  <div className="relative">
                    <span className="absolute text-xl font-bold py-2 px-2">₦</span>
                    <input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="w-44 sm:w-64 text-xl border border-black/20 rounded pl-7 pr-3 py-2"
                    />
                  </div>
                  <Button onClick={handleInvest}>Invest</Button>
                </div>

                <div className="flex">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={data?.data?.product_paper}
                    className="text-error underline cursor-pointer"
                  >
                    View Investment Details
                  </a>
                </div>
              </div>

              <div className="w-full">
                <h3 className="text-base font-bold mb-5">{`About ${data?.data?.issuer}`}</h3>
                <p className="mb-5">
                  {data?.data?.about_issuer}
                </p>
                <p>
                  website:&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue hover:underline"
                    href={data?.data?.issuer_website}
                  >
                    {data?.data?.issuer_website}
                  </a>
                </p>
              </div>
            </div>

            <div className="w-full mb-20 xl:w-5/12 xl:max-w-md xl:pl-10">
              <DealCard data={data?.data} />
            </div>
          </div>

          <div className="w-full">
            <div className="text-xs text-error mb-3">
              <h3 className="text-[#7a7a7a] font-bold mb-3">RISK WARNING</h3>
              <p className="mb-5 font-barlow">
                monieworx is the trading name for Obelix 4.1.1 Alternative Finance Limited,
                a crowdfunding intermediary licensed by the Securities and Exchange Commission (SEC).
                <br />
                Investing through the monieworx platform involves financing Small and Medium-sized
                businesses (SMEs) through debt securities issued by these SME Fundraisers.
                We advise all investors using the platform to spread their
                investments over several debt securities to limit risks.
                <br />
                If in doubt, you should seek independent financial advice before investing.
                Also, do refer to our Terms of Service for additional information before you make any investment.
              </p>
            </div>
            <Button
              fontSize="text-xs"
              paddingY="py-2"
              onClick={() => setShowRisk(true)}
            >
              Please click here to read our full Risk Warning.
            </Button>
          </div>
        </>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}

      <Modal open={showRisk} onClose={() => setShowRisk(false)}>
        <p className="text-xs text-error mb-3">
          <Interweave className="leading-loose" content={data?.data?.investment_risk || ''} />
        </p>
      </Modal>
    </ViewWrapper>
  );
}

export default DealDetails;
