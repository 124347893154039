import React from 'react';
import { BsBank, BsFillStarFill } from 'react-icons/bs';
import { useMutation, useQueryClient } from 'react-query';

import handleFetch from '../../../services/api/handleFetch';
import Loading from '../../../components/commons/Loading';
import PopoverOptions from '../../../components/commons/PopoverOptions';
import notification from '../../../utilities/notification';

function BankList({ data = [] }) {
  const queryClient = useQueryClient();
  const markAsPrimaryMutation = useMutation(handleFetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(['bank-accounts']);
      notification({
        title: 'Success',
        message: 'Primary bank account set successfully',
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const handleSetPrimary = (id) => {
    const body = { account_id: id };
    markAsPrimaryMutation.mutate({
      endpoint: 'wallet', extra: '/bank-accounts/mark-as-primary', method: 'PUT', body, auth: true
    });
  };

  const { isLoading } = markAsPrimaryMutation;

  return (
    <>
      {isLoading && <Loading />}

      <div className="w-full md:w-[calc(100%-240px)] border border-[#FFE7CC] rounded-2xl px-5 py-12">
        {data?.map((item) => (
          <div className="mb-5 sm:mb-10 last-of-type:mb-0" key={item?.id}>
            <div className="flex justify-between space-x-3 items-center relative">
              <div className="flex space-x-5 items-center w-[calc(100%-72px)]">
                <span className="bg-blue rounded-full overflow-hidden">
                  <BsBank className="text-white w-12 sm:w-16 h-12 sm:h-16 p-4" />
                </span>
                <div className="w-[calc(100%-84px)]">
                  <h3 className="font-bold text-base sm:text-lg">{item?.account_name}</h3>
                  <p className="text-blue font-semibold text-sm sm:text-base">
                    {`${item?.bank?.name} - ${item?.account_number}`}
                  </p>
                </div>
              </div>

              <div className="flex items-center min-w-max space-x-2">
                {item?.primary && (
                  <span>
                    <BsFillStarFill className="bg-[#0097A7] text-white rounded-full p-1.5 w-6 h-auto" />
                  </span>
                )}
                <PopoverOptions
                  options={item?.type === 'monieworx' ? [
                    { title: 'Set as Default', action: () => handleSetPrimary(item?.id) }
                  ] : [
                    { title: 'Set as Default', action: () => handleSetPrimary(item?.id) },
                    { title: 'Delete', action: () => {} }
                  ]}
                />
              </div>
            </div>
          </div>
        ))}

        {data?.length < 1 && (
          <div>
            You currently have not added any bank account
          </div>
        )}
      </div>
    </>
  );
}

export default BankList;
