import React from 'react';

function AuthFooter() {
  return (
    <div className="bg-white px-5 sm:px-10 py-5 sm:py-7 w-full max-w-5xl mx-auto rounded-xl">
      <div className="w-full flex flex-wrap justify-between text-[10px] sm:text-xs text-black/40">
        <p className="">
          ©&nbsp;
          {new Date().getFullYear()}
              &nbsp;monieworx
        </p>

        <ul className="flex space-x-3">
          <a href="https://monieworx.com/" target="_blank" rel="noreferrer"><li className="">Return to website</li></a>
          <a href="https://monieworx.com/contact" target="_blank" rel="noreferrer"><li className="">Support</li></a>
        </ul>
      </div>
    </div>
  );
}

export default AuthFooter;
