import React, { useState, useEffect } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useCookies } from 'react-cookie';

import Avatar from '../../../assets/images/avatar.png';

import ViewWrapper from '../../../wrappers/ViewWrapper';
import Button from '../../../components/inputs/Button';
import { formatCurrency, formatPercentage } from '../../../utilities/general';
import MakePayment from './MakePayment';
import VerificationError from '../../../components/commons/VerificationError';

function ConfirmSubscription({
  amount, data, onBack, onShowSubscription
}) {
  const [cookie,, removeCookie] = useCookies(['data', 'ac']);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState(false);

  const percentBuy = ((Number(amount) / (data?.max_amount || 1)) * 100).toFixed(2);

  useEffect(() => {
    if (cookie?.ac?.status === 'card-added') {
      setShowPaymentModal(true);
      removeCookie('ac');
    } else {
      removeCookie('ac');
    }
  }, [cookie?.ac]);

  const handleConfirm = () => {
    if (cookie?.data?.user?.verification_tier > 0) {
      setShowPaymentModal(true);
    } else {
      setShowVerificationError(true);
    }
  };

  return (
    <ViewWrapper header="Deal Details">
      <div className="mb-5 sm:mb-10">
        <button type="button" className="inline-flex items-center hover:underline" onClick={onBack}>
          <MdOutlineArrowBack className="mr-2" />
          Back
        </button>
        <h2 className="font-bold  text-2xl sm:text-3xl">Confirm subscription</h2>
      </div>

      <div className="w-full mb-16">
        <div className="flex mb-5 items-center space-x-4">
          <div>
            <img src={data?.issuer_logo} className="w-16 h-16 rounded-full bg-gray-200" alt="deal company logo" />
          </div>
          <div className="w-[calc(100%-80px)]">
            <h2 className="font-bold text-xl">
              {data?.title}
                    &nbsp;-&nbsp;
              {data?.issuer}
            </h2>
            <p className="font-bold text-base text-error">
              {`Earn ${data?.interest_rate}% interest in ${data?.tenure} days`}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mb-20">
        <div className="w-full">
          <p className="mb-5 sm:mb-10">
            {`You are subscribing to a fundraising deal from: ${data?.issuer}`}
          </p>

          <div className="flex items-center space-x-4 mb-5 sm:mb-10">
            <div>
              <img src={cookie?.data?.user?.profile_pic || Avatar} className="w-14 h-auto rounded-full" alt="investor" />
            </div>
            <div>
              <h2 className="font-bold text-sm text-primary">Investor</h2>
              <p className="font-bold text-base">{`${cookie?.data?.user?.f_name} ${cookie?.data?.user?.l_name}`}</p>
            </div>
          </div>

          <div className="mb-5 sm:mb-10">
            <h2 className="font-bold text-sm text-primary">Investment info</h2>
            <p className="font-bold text-base">{`${data?.max_amount_formatted} discount notes`}</p>
          </div>

          <div className="flex space-x-14 mb-5 sm:mb-10">
            <div className="">
              <h2 className="font-bold text-sm text-primary">Amount invested</h2>
              <p className="font-bold text-base">{formatCurrency(amount)}</p>
            </div>
            <div className="">
              <h2 className="font-bold text-sm text-primary">% of deal bought</h2>
              <p className="font-bold text-base">{formatPercentage(percentBuy)}</p>
            </div>
          </div>

          <Button onClick={handleConfirm}>Confirm</Button>
          <p className="ext-xs mt-3">You will be directed to a payment page upon confirmation</p>
        </div>
      </div>

      {showPaymentModal && (
        <MakePayment
          amount={amount}
          offerId={data?.id}
          onClose={() => setShowPaymentModal(false)}
          onShowSubscription={onShowSubscription}
        />
      )}

      {showVerificationError && (
        <VerificationError onClose={() => setShowVerificationError(false)} />
      )}
    </ViewWrapper>
  );
}

export default ConfirmSubscription;
