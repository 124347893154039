import React, { useEffect, useState } from 'react';
import Loading from '../../components/commons/Loading';
import useGetQuery from '../../hooks/useGetQuery';
import notification from '../../utilities/notification';

import ViewWrapper from '../../wrappers/ViewWrapper';
import Deals from './components/Deals';
import PerformanceCards from './components/PerformanceCards';
import CompleteProfile from './components/CompleteProfile';

function index() {
  const dataStore = localStorage?.getItem('d');
  const [store, setStore] = useState(null);
  const { data, status, error } = useGetQuery({ endpoint: 'user', queryKey: ['user'] });

  useEffect(() => {
    if (dataStore) {
      setStore(JSON.parse(dataStore));
    }
  }, [dataStore]);

  useEffect(() => {
    if (status === 'error') {
      notification({
        title: 'Error',
        message: error?.toString() || 'Something went wrong while fetching data',
        type: 'danger'
      });
    } else if (status === 'success') {
      localStorage.removeItem('d');
      setStore(null);
    }
  }, [status]);

  return (
    <ViewWrapper header="Home">
      {(!dataStore && status === 'loading') && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Overview</h2>
      </div>

      <div className="w-full mb-16">
        <PerformanceCards data={store || data?.data} />

        <div className="w-full xl:w-2/3 2xl:w-1/2">
          <CompleteProfile />
        </div>
      </div>

      <div className="w-full">
        <h3 className="font-bold text-2xl sm:text-3xl mb-2 sm:mb-5">Crowd Favorites</h3>
        <p className="text-[#5F7C51] text-base">The most loved deals on monieworx today</p>

        <div className="w-full xl:w-2/3 2xl:w-1/2">
          <Deals data={store?.offers || data?.data?.offers} />
        </div>
      </div>
    </ViewWrapper>
  );
}

export default index;
