import React, { useState, useEffect, useRef } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { CgCopy } from 'react-icons/cg';
import { useMutation, useQueryClient } from 'react-query';
import CopyToClipboard from 'react-copy-to-clipboard';

import handleFetch from '../../../services/api/handleFetch';
import Loading from '../../../components/commons/Loading';

import Button from '../../../components/inputs/Button';
import SelectInput from '../../../components/inputs/Select';
import TextInput from '../../../components/inputs/Text';
import { formatApiDate } from '../../../utilities/dateTime';
import { capitalize, convertImgToBase64 } from '../../../utilities/general';
import notification from '../../../utilities/notification';

function PersonalTab({ data }) {
  const [form, setForm] = useState({});
  const [refForm, setRefForm] = useState({});
  const [hasChanged, setHasChanged] = useState(false);

  const updatedProfilePic = useRef(false);

  useEffect(() => {
    if (data?.id) {
      const user = {
        f_name: data?.f_name,
        l_name: data?.l_name,
        m_name: data?.m_name,
        monie_tag: data?.monie_tag,
        phone: data?.phone,
        email: data?.email,
        profile_pic: data?.profile_pic,
        address: data?.address,
        city: data?.city,
        state: data?.state,
        country: data?.country,
        dob: formatApiDate(data?.dob),
        gender: { label: capitalize(data.gender), value: data?.gender }
      };
      setForm(user);
      setRefForm(user);
    }
  }, [data]);

  useEffect(() => {
    setHasChanged(JSON.stringify(form) !== JSON.stringify(refForm));
  }, [form, refForm]);

  useEffect(() => {
    if (updatedProfilePic.current) {
      localStorage.setItem('ppic', form?.profile_pic);
      updatedProfilePic.current = false;
    }
  }, [updatedProfilePic.current]);

  const handleChange = (val, type = 'input', inputName = '') => {
    if (type === 'input') {
      const { value, name } = val.target;
      setForm((state) => ({ ...state, [name]: value }));
    } else {
      setForm((state) => ({ ...state, [inputName]: val }));
    }
  };

  const handlePicUpload = () => {
    const uploadField = document.getElementById('logo-file');

    uploadField.click();
    uploadField.onchange = async () => {
      if (!uploadField.files[0]) return;

      const { type, size } = uploadField.files[0];
      const fileType = type.slice(type.indexOf('/') + 1);

      if (!(fileType === 'jpg' || fileType === 'png' || fileType === 'jpeg')) {
        notification({
          title: 'Invalid File Type',
          message: 'Invalid file format. Supported file types are jpg, jpeg and png.',
          type: 'danger'
        });
        return;
      }

      if (size / 1024 > 2000) {
        notification({
          title: 'File Too Large',
          message: 'The file size must not be more than 2MB',
          type: 'danger'
        });
        return;
      }

      const file = await convertImgToBase64(uploadField.files[0]);
      handleChange(file, 'file', 'profile_pic');
    };
  };

  const queryClient = useQueryClient();
  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user-account']);
      if (refForm?.profile_pic !== form?.profile_pic) {
        updatedProfilePic.current = true;
      }
      notification({
        title: 'Success',
        message: 'You have successfully updated your profile',
        type: 'success'
      });
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateForm = () => {
    if (!form?.profile_pic) return 'Profile picture is required.';
    if (!form?.address) return 'Address is required.';
    if (!form?.city) return 'City is required';
    if (!form?.state) return 'State is required';
    if (!form?.country) return 'Country is required';
    if (!form?.dob) return 'Date of birth is required';
    if (!form?.gender) return 'Gender is required';
    return null;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const err = validateForm();

    if (err) {
      notification({
        title: 'Form Error',
        message: err,
        type: 'danger'
      });
      return;
    }

    const body = {
      address: form?.address,
      city: form?.city,
      state: form?.state,
      country: form?.country,
      dob: form?.dob,
      gender: form?.gender?.value
    };

    if (refForm?.profile_pic !== form?.profile_pic) {
      body.photo_base64 = form?.profile_pic;
    }

    submitMutation.mutate({
      endpoint: 'user', extra: '/edit-profile', method: 'POST', body
    });
  };

  const { isLoading } = submitMutation;

  return (
    <div className="w-full">
      {isLoading && <Loading />}

      <div className="flex flex-wrap -m-3">
        <div className="w-full md:w-1/2 p-3">
          <div className="flex -mx-2 mb-7">
            <div className="w-[150px] px-2 relative">
              <img
                src={form?.profile_pic}
                alt="profile"
                className="w-full h-full bg-gray-300 rounded-xl object-cover object-center"
              />
              <input hidden type="file" id="logo-file" />
              <button
                type="button"
                className="absolute -right-0 -bottom-2 bg-primary rounded-full p-1.5"
                onClick={handlePicUpload}
              >
                <MdModeEdit className="text-white w-5 h-5" />
              </button>
            </div>
            <div className="w-[calc(100%-150px)] px-2">
              <div className="w-full">
                <TextInput
                  label="First Name"
                  className="mb-7"
                  value={form?.f_name || ''}
                  readOnly
                  inputBg="bg-[#FFFBFB]"
                />
                <TextInput
                  label="Last Name"
                  className=""
                  value={form?.l_name || ''}
                  readOnly
                  inputBg="bg-[#FFFBFB]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-3">
          <div className="w-full max-w-[350px]">
            <TextInput
              label="monietag"
              className="mb-7"
              value={form?.monie_tag ? `@${form?.monie_tag}` : ''}
              readOnly
              inputBg="bg-[#FFFBFB]"
            />
            {data?.affiliate_id && (
              <div className="relative border-t border-primary mb-7">
                <span
                  className="absolute inline-block text-xs text-primary bg-[#FFFBFB] px-2 mx-4 -mt-2"
                >
                  Referral Code
                </span>
                <CopyToClipboard
                  text={data?.affiliate_id}
                  onCopy={() => notification({
                    message: 'Copied referral code to clipboard',
                    type: 'success'
                  })}
                >
                  <Button
                    paddingX="px-5"
                    paddingY="py-[9px]"
                    className="mt-4"
                  >
                    {data?.affiliate_id}
                    <CgCopy className="text-white ml-3" />
                  </Button>
                </CopyToClipboard>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -m-3">
        <div className="w-full md:w-1/2 p-3">
          <div className="w-full">
            <TextInput
              label="Address"
              placeholder="Enter your street address"
              className="mb-7"
              name="address"
              value={form?.address || ''}
              onChange={handleChange}
              inputBg="bg-[#FFFBFB]"
            />
            <TextInput
              label="City"
              placeholder="Enter your city of residence"
              className="mb-7"
              name="city"
              value={form?.city || ''}
              onChange={handleChange}
              inputBg="bg-[#FFFBFB]"
            />
            <TextInput
              label="State"
              placeholder="Enter your state of residence"
              className="mb-7"
              name="state"
              value={form?.state || ''}
              onChange={handleChange}
              inputBg="bg-[#FFFBFB]"
            />
            <TextInput
              label="Country"
              placeholder="Enter your country of residence"
              className="mb-7"
              name="country"
              value={form?.country || ''}
              onChange={handleChange}
              inputBg="bg-[#FFFBFB]"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 p-3">
          <div className="w-full">
            <TextInput
              type="date"
              label="Date of Birth"
              className="mb-7"
              name="dob"
              value={form?.dob || ''}
              onChange={handleChange}
              inputBg="bg-[#FFFBFB]"
            />
            <SelectInput
              label="Gender"
              options={[{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }]}
              placeholder="Select your gender"
              className="mb-7"
              value={form?.gender}
              onChange={(val) => handleChange(val, 'select', 'gender')}
              inputBg="!bg-[#FFFBFB]"
            />
            <TextInput
              label="Email"
              className="mb-7"
              value={form?.email || ''}
              readOnly
              inputBg="bg-[#FFFBFB]"
            />
            <TextInput
              label="Phone number"
              className="mb-7"
              value={form?.phone || ''}
              readOnly
              inputBg="!bg-[#FFFBFB]"
            />
          </div>
        </div>
      </div>

      <div className="flex mt-5">
        <Button
          className="rounded-full"
          paddingY="py-4"
          paddingX="px-20"
          bgColor="bg-error"
          disabled={!hasChanged}
          onClick={handleSubmit}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
}

export default PersonalTab;
