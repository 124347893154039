import React from 'react';

import Skeleton from 'react-loading-skeleton';

import PortfolioIcon from '../../../assets/svgs/portfolio-icon.svg';
import IncomeIcon from '../../../assets/svgs/income-icon.svg';

export function Card({
  icon, title, amount, description, percentage, bgColor
}) {
  return (
    <div style={{ backgroundColor: bgColor }} className="w-full h-full rounded-xl px-7 py-5">
      <div className="flex items-center space-x-3 mb-2 sm:mb-4">
        <img src={icon} className="w-8 h-auto" alt={title} />
        <h4 className="text-lg font-bold">{title}</h4>
      </div>
      {amount ? (
        <div className="flex flex-wrap items-center justify-between mb-2 sm:mb-4">
          <h4 className="text-3xl sm:text-4xl font-bold">{amount}</h4>
          <h4 className="text-base">{percentage}</h4>
        </div>
      ) : (
        <h4 className="text-3xl sm:text-4xl font-bold mb-2 sm:mb-4"><Skeleton baseColor={bgColor} /></h4>
      )}
      <p className="">{description}</p>
    </div>
  );
}

function PerformanceCards({ data }) {
  return (
    <div className="flex flex-wrap -mx-3">
      <div className="p-3 w-full sm:w-1/2 xl:w-1/3">
        <Card
          icon={PortfolioIcon}
          bgColor="#FFE7CC"
          title="Portfolio"
          amount={data?.total_portfolio_value}
          percentage={`${data?.percent_profit}%`}
          description="Your total invested with expected returns"
        />
      </div>
      <div className="p-3 w-full sm:w-1/2 xl:w-1/3">
        <Card
          icon={IncomeIcon}
          bgColor="#E0FFB8"
          title="Income"
          amount={data?.total_income_short}
          description="Your paid returns on investment"
        />
      </div>
    </div>
  );
}

export default PerformanceCards;
