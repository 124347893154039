import React from 'react';
import Loading from '../../components/commons/Loading';
import useGetQuery from '../../hooks/useGetQuery';

import ViewWrapper from '../../wrappers/ViewWrapper';
import CardsSlide from './components/CardsSlide';
// import CardTransactions from './components/CardTransactions';

function Cards() {
  const {
    data, status, error, isFetching
  } = useGetQuery({ endpoint: 'wallet', queryKey: ['get-cards'] });

  return (
    <ViewWrapper header="Cards">
      {(status === 'loading' || isFetching) && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Cards</h2>
      </div>

      {status === 'success' && (
        <div className="w-full">
          <h4 className="text-base font-semibold mb-2 sm:mb-5">Connected cards:</h4>

          <div className="w-full mb-16 max-w-7xl">
            <CardsSlide data={data?.data} />
          </div>

          {/* <div className="w-full">
            <CardTransactions />
          </div> */}
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default Cards;
