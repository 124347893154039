import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import AuthCode from 'react-auth-code-input';
import { useMutation, useQueryClient } from 'react-query';
import { useCookies } from 'react-cookie';

import Avatar from '../../../assets/images/avatar.png';
import Button from '../../../components/inputs/Button';
import Loading from '../../../components/commons/Loading';
import notification from '../../../utilities/notification';
import handleFetch from '../../../services/api/handleFetch';
import useGetQuery from '../../../hooks/useGetQuery';

function VerifyPhone({ onClose = () => {}, phone = '' }) {
  const [cookie, setCookie] = useCookies(['data']);
  const [callApiFlag, setCallApiFlag] = useState(true);
  const [otp, setOtp] = useState('');

  const {
    data, status, error, isFetching
  } = useGetQuery({
    endpoint: 'user', extra: '/phone/send-otp', method: 'POST', enabled: callApiFlag
  });

  useEffect(() => {
    if (status === 'success') {
      setCallApiFlag(false);
      notification({
        title: 'Success',
        message: data?.msg || 'Verification code has been sent.',
        type: 'success'
      });
    } else if (status === 'error') {
      setCallApiFlag(false);
      notification({
        title: 'Error',
        message: String(error) || 'Something went wrong. Please, try again.',
        type: 'danger'
      });
    }
  }, [status]);

  const queryClient = useQueryClient();
  const submitMutation = useMutation(handleFetch, {
    onSuccess: () => {
      const { user, token } = cookie.data;
      setCookie('data', { token, user: { ...user, phone_verified_at: 'true' } });
      queryClient.invalidateQueries(['user-account']);
      onClose();
    },
    onError: (err) => {
      notification({
        title: 'Error',
        message: err?.toString() || 'Something went wrong.',
        type: 'danger'
      });
    }
  });

  const validateForm = () => {
    if (otp?.length < 4) return 'OTP is required';
    return null;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const err = validateForm();

    if (err) {
      notification({
        title: 'Form Error',
        message: err,
        type: 'danger'
      });
      return;
    }

    submitMutation.mutate({
      endpoint: 'user', extra: '/phone/verify-otp', method: 'POST', body: { otp }
    });
  };

  const { isLoading } = submitMutation;

  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-xl' }}>
      {(status === 'loading' || isFetching || isLoading) && <Loading />}

      <div className="w-full p-5">
        <h2 className="font-bold text-2xl mb-5 sm:mb-10">Verify your phone number</h2>

        <div className="flex items-center space-x-3 mb-6">
          <span className="">
            <img src={Avatar} alt="" className="w-16 h-16" />
          </span>
          <div className="">
            <p className="text-primary font-semibold mb-2">Linked phone number</p>
            <p className="bg-[#FEF4E9] font-bold text-lg px-5 py-2 rounded-3xl">{phone}</p>
          </div>
        </div>

        {!isFetching && status === 'success' && (
          <>
            <p className="mb-5">
              Enter 4-digit Code code we have sent to&nbsp;
              <span className="font-bold">{phone}</span>
            </p>

            <div className="max-w-sm">
              <AuthCode
                value={otp || ''}
                length={4}
                allowedCharacters="numeric"
                containerClassName="w-full flex space-x-3"
                inputClassName="w-1/4 border-b-2 border-blue focus:border-primary outline-none text-center text-3xl font-bold text-blue focus:text-primary py-2"
                onChange={(val) => setOtp(val)}
              />
            </div>

            <Button
              className="rounded-full mt-10"
              paddingY="py-4"
              paddingX="px-20"
              bgColor="bg-error"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </>
        )}

        {(status === 'loading' || isFetching) && (
          <p className="mb-5 sm:mb-10">Sending verification code...</p>
        )}

        {status === 'error' && (
          <p className="mb-5 sm:mb-10">
            {String(error)}
          </p>
        )}

        <p className="mt-5 text-primary">
          <span className="">Didn’t receive the code?&nbsp;</span>
          <button
            type="button"
            className="font-bold text-base hover:underline"
            onClick={() => setCallApiFlag(true)}
          >
            Resend Code
          </button>
        </p>
      </div>
    </Modal>
  );
}

export default VerifyPhone;
