import React from 'react';

import ViewWrapper from '../../wrappers/ViewWrapper';
// import PeriodSelect from '../../components/inputs/PeriodSelect';
import PerformanceCards from './components/PerformanceCards';
import TransactionList from './components/TransactionList';
import useGetQuery from '../../hooks/useGetQuery';
import Loading from '../../components/commons/Loading';

function Transactions() {
  const { data, status, error } = useGetQuery({ endpoint: 'wallet', queryKey: ['wallet'] });

  return (
    <ViewWrapper header="Transactions">
      {status === 'loading' && <Loading />}

      <div className="mb-8 sm:mb-16">
        <h2 className="font-bold  text-2xl sm:text-3xl">Transactions</h2>
      </div>

      {status === 'success' && (
        <div className="w-full max-w-3xl">
          {/* <div className="flex items-center mb-2 ms:mb-5">
            <h4 className="text-base font-semibold mr-4">Your transaction details for:</h4>
            <PeriodSelect />
          </div> */}

          <div className="w-full mb-12 sm:mb-20">
            <PerformanceCards data={data?.data?.transactions} />
          </div>

          <div className="w-full">
            <TransactionList data={data?.data?.transactions?.transactions} />
          </div>
        </div>
      )}

      {status === 'error' && (
        <div className="py-10">
          {String(error)}
        </div>
      )}
    </ViewWrapper>
  );
}

export default Transactions;
