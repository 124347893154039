import React from 'react';

import IncomeIcon from '../../../assets/svgs/income-icon.svg';

// import PeriodSelect from '../../../components/inputs/PeriodSelect';
import { formatCurrency } from '../../../utilities/general';
import { Card } from './PerformanceCards';

function IncomeStat({ data }) {
  return (
    <div className="w-full mb-16">
      {/* <div className="flex items-center mb-5">
        <h4 className="text-base font-semibold mr-4">Your interest earned in:</h4>
        <PeriodSelect />
      </div> */}
      <div className="flex flex-wrap items-center md:space-x-10">
        <div className="w-full md:w-1/2">
          <Card
            icon={IncomeIcon}
            bgColor="#E0FFB8"
            title="Income"
            amount={formatCurrency(data?.total_interest_earned)}
            description="Your earned returns on investment"
          />
        </div>
        <div className="border border-primary rounded-md px-7 py-5 mt-5 md:mt-0">
          <div className="">
            <h2 className="font-bold text-sm text-primary">Average Weekly Income</h2>
            <p className="font-bold text-base">{formatCurrency(data?.avg_weekly_income)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncomeStat;
