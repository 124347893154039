import React from 'react';
import { useNavigate } from 'react-router-dom';
import NoData from '../../../components/commons/NoData';
import Button from '../../../components/inputs/Button';
import { formatCurrency } from '../../../utilities/general';

function Deals({ data = [] }) {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      {data?.map((item) => (
        <div className="border border-[#FFE7CC] bg-[#FAA74A1F] mt-8 px-4 sm:px-8 py-5 rounded-3xl" key={item.id}>
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center space-x-4">
              <div>
                <img
                  src={item?.offer?.issuer_logo}
                  className="w-24 h-24 rounded-full bg-gray-100"
                  alt={item?.offer?.title}
                />
              </div>
              <div className="w-[calc(100%-112px)]">
                <div className="flex flex-wrap relative mb-2 items-center">
                  <h3 className="text-xl font-bold mr-2">{item?.offer?.max_amount_formatted}</h3>
                  <p className="text-xs min-w-max">{`(${item?.offer?.percent_raised}% raised)`}</p>
                  {!item?.paid_at && (
                    <span className="text-white absolute right-0 -top-5 sm:top-0 font-bold text-xs bg-error/70 px-3 py-1 rounded-lg">UNPAID</span>
                  )}
                </div>
                <p className="font-bold italic mb-1">
                  {`You invested ${item?.invested_amount_formatted
                  } to earn ${formatCurrency(item?.total_interest_payable)} interest`}
                </p>
                <p className="">
                  {item?.offer?.title}
                  &nbsp;-&nbsp;
                  {item?.offer?.issuer}
                </p>
              </div>
            </div>

            <div className="w-full sm:w-auto mt-5">
              <Button
                bgColor="bg-primary"
                paddingX="px-3"
                paddingY="py-2"
                fontSize="text-base"
                className="w-full sm:w-auto"
                onClick={() => navigate(`/portfolio/investment-details/${item?.id}`)}
              >
                View Details
              </Button>
            </div>
          </div>
        </div>
      ))}

      {data?.length < 1 && (
        <NoData />
      )}
    </div>
  );
}

export default Deals;
