import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { FiMenu } from 'react-icons/fi';

import '../../assets/styles/sidebar.scss';
import Logo from '../../assets/svgs/logo.svg';

import sidebarMenu from '../../configs/sidebarMenu';
import useClickOutsideBox from '../../hooks/useClickOutsideBox';

function Sidebar() {
  const menuList = sidebarMenu();
  const wrapperRef = useRef(null);

  const [showMenu, setShowMenu] = useState(false);

  useClickOutsideBox(wrapperRef, () => setShowMenu(false));

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const btnStyle = 'transition ease-in-out w-9 p-1 h-auto bg-primary hover:text-white bg-opacity-5 text-gray-900 rounded hover:bg-opacity-100';

  return (
    <div ref={wrapperRef}>
      <div className={`${showMenu ? 'left-[240px]' : 'left-6'} top-5 fixed cursor-pointer z-30 lg:hidden`}>
        {showMenu ? (
          <IoClose
            onClick={toggleMenu}
            className={btnStyle}
          />
        ) : (
          <FiMenu
            onClick={toggleMenu}
            className={btnStyle}
          />
        )}
      </div>

      <nav className={`z-20 page-sidebar fixed w-72 bg-white h-screen border-r ${showMenu ? 'show' : ''}`}>
        <div className="sidebar-header flex h-20 px-8 py-4">
          <img src={Logo} className="w-40 h-auto" alt="Logo" />
        </div>

        <div className="sidebar-menu max-h-[calc(100%-80px)] flex flex-col space-y-5 justify-between h-side-menu pt-4 pb-10">
          {menuList?.map((item) => (
            <MenuSection section={item?.section} menu={item?.menu} toggleMenu={toggleMenu} key={item.section} />
          ))}
        </div>
      </nav>
    </div>
  );
}

function MenuSection({ section, menu, toggleMenu }) {
  return (
    <div className="">
      <h3 className="pl-8 mb-2 text-black/40">{section}</h3>
      <ul className="menu-items">
        {menu?.map((item) => (
          <MenuItem props={item} toggleMenu={toggleMenu} key={item.title} />
        ))}
      </ul>
    </div>
  );
}

function MenuItem({
  props: {
    title, href, link, icon, children
  }, toggleMenu
}) {
  const location = useLocation();
  const { pathname } = location;
  const [isShowingSub, setIsShowingSub] = useState(true);

  return (
    <>
      {children ? (
        <li className={`${pathname?.includes(link) ? 'active' : ''} justify-between`}>
          <Link to={link} onClick={toggleMenu} className="w-full">
            <span className="flex">
              {icon}
              <span
                className={children ? 'has-sub-menu' : ''}
              >
                <span className="title">{title}</span>
              </span>
            </span>
          </Link>
          {isShowingSub
            ? <IoIosArrowDown className="cursor-pointer hover:bg-white/40" onClick={() => setIsShowingSub(!isShowingSub)} />
            : <IoIosArrowBack className="cursor-pointer hover:bg-white/40" onClick={() => setIsShowingSub(!isShowingSub)} />}
        </li>
      ) : (
        <>
          {href && (
            <a href={href} target="_blank" rel="noreferrer">
              <li className="">
                {icon}
                <span className="title">{title}</span>
              </li>
            </a>
          )}
          {link && (
            <Link to={link} onClick={toggleMenu}>
              <li className={`${pathname?.includes(link) ? 'active' : ''}`}>
                {icon}
                <span className="title">{title}</span>
              </li>
            </Link>
          )}
        </>
      )}

      {children && isShowingSub && (
        <ul className={`${isShowingSub ? 'show' : ''} sub-menu`}>
          {children?.map((sub) => (
            <Link to={sub?.link} onClick={toggleMenu} key={sub?.title}>
              <li className={`${pathname?.includes(sub?.link) ? 'active font-bold' : ''}`}>
                <IoIosArrowForward className="!w-4 mr-2.5 text-black/20" />
                {sub?.title}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </>
  );
}

export default Sidebar;
