import Echo from 'laravel-echo';
import Cookies from 'js-cookie';

window.Pusher = require('pusher-js');

function createSocketConnection() {
  const headers = {
    'Content-Type': 'application/json',
    common: '{ \'X-Requested-With\': \'XMLHttpRequest\' }'
  };
  const data = Cookies.get('data') && JSON.parse(Cookies.get('data'));
  headers.Authorization = `Bearer ${data?.token}`;

  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      auth: { headers },
      key: process.env.REACT_APP_MIX_PUSHER_APP_KEY,
      wsHost: process.env.REACT_APP_MIX_PUSHER_HOST,
      wsPort: process.env.REACT_APP_MIX_PUSHER_PORT,
      wssPort: process.env.REACT_APP_MIX_PUSHER_PORT,
      cluster: process.env.REACT_APP_MIX_PUSHER_CLUSTER,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss']
    });
  }
}

export default createSocketConnection;
