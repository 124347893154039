import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-responsive-modal';

import SuccessIcon from '../../../../assets/svgs/success.svg';

import Button from '../../../../components/inputs/Button';

function SignupSuccess() {
  const navigate = useNavigate();

  return (
    <Modal
      classNames={{ modal: 'rounded-md m-3 !max-w-md' }}
      open
      showCloseIcon={false}
      onClose={() => {}}
      center
    >
      <center className="px-5 py-3">
        <img src={SuccessIcon} alt="" className="mb-2 sm:mb-4" />
        <h3 className="font-semibold mb-2 sm:mb-4">
          Successful Registration
        </h3>
        <div className="mb-8">
          <p className="">You have successfully onboarded on our platform.</p>
        </div>
        <div className="flex justify-center">
          <Button onClick={() => navigate('/home')}>
            Goto Dashboard
          </Button>
        </div>
      </center>
    </Modal>
  );
}

export default SignupSuccess;
