import React from 'react';

function TokenIcon({ className = '', color = '#232323' }) {
  return (
    <svg width="12" height="18" className={className} viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.40629 0.857208H3.70017V4.06986H0.849609V6.51468H9.04642C9.10762 6.51468 9.1565 6.53964 9.19326 6.58935C9.21895 6.60888 9.23775 6.63176 9.24986 6.65807C9.26114 6.6821 9.26678 6.70894 9.26678 6.7387V8.25046H0.849609V12.3191C0.849609 12.7918 0.959897 13.2211 1.18047 13.6068C1.41316 13.9925 1.71959 14.3037 2.09933 14.54C2.49139 14.7763 2.92022 14.8946 3.38581 14.8946H3.70017V17.8963H5.40629V14.8946H7.11742V17.8963H8.82354V14.8946H11.6743V6.64533C11.6743 6.17264 11.5578 5.74329 11.3251 5.3576C11.1047 4.9719 10.7983 4.66078 10.4062 4.42443C10.237 4.31905 10.0593 4.23717 9.87295 4.17879C9.64109 4.10621 9.39608 4.06986 9.13811 4.06986H8.82354V0.857208H7.11742V4.06986H5.40629V0.857208ZM3.47772 12.4498H9.26678V10.4527H3.25714V12.2257C3.25714 12.288 3.27552 12.344 3.31229 12.3937C3.33505 12.411 3.35907 12.4243 3.38435 12.4336C3.41359 12.4443 3.44471 12.4498 3.47772 12.4498Z" fill={color} />
    </svg>
  );
}

export default TokenIcon;
