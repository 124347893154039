import React from 'react';
import Modal from 'react-responsive-modal';
import YouTube from 'react-youtube';

function VideoGuide({ onClose }) {
  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-2xl' }}>
      <div className="w-full">
        <h3 className="text-2xl font-bold text-primary mb-4">Video Setup Guide</h3>
        <YouTube videoId="0tyh4k5WmqE" iframeClassName="w-full h-[280px] sm:h-[350px]" />
      </div>
    </Modal>
  );
}

export default VideoGuide;
