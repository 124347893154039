import React, {
  Fragment, useEffect, useRef, useState
} from 'react';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { Popover, Transition } from '@headlessui/react';
// import { useMutation, useQueryClient } from 'react-query';
import { useCookies } from 'react-cookie';

import Tabs from './Tabs';
import Financials from './Financials';
import NonFinancials from './NonFinancials';
import VideoGuide from './VideoGuide';
import useGetQuery from '../../../hooks/useGetQuery';
// import handleFetch from '../../../services/api/handleFetch';
// import notification from '../../../utilities/notification';

function Notifications() {
  const [cookie] = useCookies(['data']);
  const [currentTab, setCurrentTab] = useState(0);
  const [hasNotification, setHasNotification] = useState(false);
  const [showVGuide, setShowVGuide] = useState(false);
  const isMarkedRead = useRef(false);

  const { user } = cookie?.data || {};

  const { data, status, error } = useGetQuery({ endpoint: 'notifications', queryKey: ['notifications'] });

  useEffect(() => {
    if (data?.data?.has_unread) {
      setHasNotification(true);
    }
    if (user?.verification_tier < 1 || user?.balance === '0.00' || !user?.phone_verified_at) {
      setHasNotification(true);
      setCurrentTab(1);
    }
  }, [user, data]);

  // const queryClient = useQueryClient();
  // const notificationMutation = useMutation(handleFetch, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(['notifications']);
  //     isMarkedRead.current = true;
  //   },
  //   onError: (err) => {
  //     notification({
  //       title: 'Error',
  //       message: err?.toString() || 'Something went wrong.',
  //       type: 'danger'
  //     });
  //   }
  // });

  const handleView = () => {
    if (isMarkedRead.current || !data?.data?.has_unread) return;

    setTimeout(() => {
      // notificationMutation.mutate({ endpoint: 'notifications', extra: '/mark-as-read', method: 'POST' });
    }, 2000);
  };

  return (
    <Popover className="relative">
      {() => (
        <>
          <Popover.Button className="outline-none" onClick={handleView}>
            <div className="relative">
              <IoIosNotificationsOutline className="mr-4 w-7 h-auto" />
              {hasNotification && <span className="w-2 h-2 absolute bg-error rounded-full top-1 left-4" />}
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="fixed sm:absolute z-10 mt-3 w-screen max-w-md left-1/2 -translate-x-1/2 transform px-4">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative bg-white py-7">
                  <div className="w-full px-7">
                    <h2 className="text-xl text-primary font-bold mb-5">Notifications</h2>
                    <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
                  </div>

                  <div className="w-full max-h-80 overflow-auto px-7 mt-7">
                    {currentTab === 0 && <Financials data={data} error={error} status={status} />}
                    {currentTab === 1 && (
                      <NonFinancials
                        data={data}
                        error={error}
                        status={status}
                        handleShowVGuide={() => setShowVGuide(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>

          {showVGuide && <VideoGuide onClose={() => setShowVGuide(false)} />}
        </>
      )}
    </Popover>
  );
}

export default Notifications;
