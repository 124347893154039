import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import createSocketConnection from '../services/socketio';

function listen(callBack, channel, event) {
  window.Echo.channel(channel).listen(event, (payload) => {
    callBack(payload);
  });

  return function cleanUp() {
    window.Echo.leaveChannel(channel);
  };
}

const useSocket = ({ type, callBack }, dependency = []) => {
  const [cookie] = useCookies(['data']);
  const { user } = cookie.data;

  useEffect(() => {
    createSocketConnection();

    switch (type) {
    case 'EMAIL_VERIFIED': {
      return listen(callBack, `email_verified.${user?.id}`, 'EmailVerifiedEvent');
    }
    case 'WALLET_FUNDING': {
      return listen(callBack, `wallet_funded.${user?.id}`, 'WalletFundingEvent');
    }
    case 'WALLET_WITHDRAWAL': {
      return listen(callBack, `wallet_withdrawal.${user?.id}`, 'WalletWithdrawalEvent');
    }
    default: return null;
    }
  }, dependency);
};

export default useSocket;
