import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';

import Avatar from '../../../assets/images/avatar.png';
import Button from '../../../components/inputs/Button';
import Loading from '../../../components/commons/Loading';
import notification from '../../../utilities/notification';
import useGetQuery from '../../../hooks/useGetQuery';

function VerifyEmail({ onClose = () => {}, email = '' }) {
  const [callApiFlag, setCallApiFlag] = useState(true);

  const { data, status, error } = useGetQuery({
    endpoint: 'user',
    extra: '/email/verification-notification',
    method: 'POST',
    queryKey: ['verification-notification', 'email'],
    enabled: callApiFlag
  });

  useEffect(() => {
    if (status === 'success') {
      setCallApiFlag(false);
      notification({
        title: 'Success',
        message: data?.msg || 'Verification link has been sent.',
        type: 'success'
      });
    } else if (status === 'error') {
      setCallApiFlag(false);
      notification({
        title: 'Error',
        message: String(error) || 'Something went wrong. Please, try again.',
        type: 'danger'
      });
    }
  }, [status]);

  return (
    <Modal open onClose={onClose} classNames={{ modal: 'w-[calc(100%-40px)] !max-w-xl' }}>
      {status === 'loading' && <Loading />}

      <div className="w-full p-5">
        <h2 className="font-bold text-2xl mb-5 sm:mb-10">Verify your email</h2>

        <div className="flex items-center space-x-3 mb-6">
          <span className="">
            <img src={Avatar} alt="" className="w-16 h-16" />
          </span>
          <div className="">
            <p className="text-primary font-semibold mb-2">Linked email address</p>
            <p className="bg-[#FEF4E9] font-bold text-lg px-5 py-2 rounded-3xl">{email}</p>
          </div>
        </div>

        {status === 'loading' && (
          <p className="mb-5 sm:mb-10">Sending verification link...</p>
        )}

        {status === 'success' && (
          <>
            <p className="mb-2">
              The verification link has been sent to&nbsp;
              <span className="font-bold">{email}</span>
            </p>
            <p className="mb-5 sm:mb-10">After verification, kindly refresh page.</p>
          </>
        )}

        {status === 'error' && (
          <p className="mb-5 sm:mb-10">
            {String(error)}
          </p>
        )}

        <p className="mb-2 sm:mb-4 text-primary">Didn’t receive the verification link?</p>

        <Button
          className="rounded-full"
          paddingY="py-4"
          paddingX="px-20"
          bgColor="bg-[#C7FFAB]"
          textColor="text-[#5E9942]"
          onClick={() => setCallApiFlag(true)}
        >
          Resend
        </Button>

      </div>
    </Modal>
  );
}

export default VerifyEmail;
